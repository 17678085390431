import ENV from 'config/environment';
import { LANG } from 'core/general-setup';
import { useAppDispatch } from 'store';
import { addDefaultPage, addToken, addUser, logout } from 'store/reducers/user.reducer';
import { AvailableLanguages } from 'utils/enums';
import { ArrayString } from 'utils/interfaces';
import { Nullable } from 'utils/types';
import { useIitGet } from './http/iit-use-get';
import { STRUCTURE } from 'structure';
import { DEFAULT_ROUTE } from 'utils/constants';

interface IUseMe<T> {
   onCompleted?: (data: Nullable<T>) => void;
}

interface IGetFullName {
   firstName: string;
   surname: string;
   email: string;
}

const getFullName = ({ firstName, surname, email }: IGetFullName) => {
   if (!firstName || !surname) return email.split('@')[0];

   return `${firstName} ${surname}`;
};

export const transformUserData = (userData: ArrayString) => {
   const API_ENDPOINT = ENV.REACT_APP_API_ENDPOINT.replace('/api', '');
   return {
      ...userData,
      ...(userData?.role?.name && { roles: [userData?.role?.name] }),
      ...(userData?.profileImage && {
         image:
            userData?.profileImage?.indexOf(API_ENDPOINT) === -1
               ? `${API_ENDPOINT}${userData?.profileImage}`
               : userData?.profileImage,
      }),
      fullName: getFullName({
         firstName: userData?.firstName,
         surname: userData?.surname,
         email: userData?.email,
      }),
   };
};

const useIitMe = <T>(skip = false, { onCompleted }: IUseMe<T> = {}) => {
   const dispatch = useAppDispatch();

   const {
      refetch: getMe,
      loading,
      data,
      error,
   } = useIitGet('users/me?populate=role,profileImage', {
      skip: skip || !localStorage.getItem('token'),
      onCompleted: (data: Nullable<ArrayString>) => {
         onCompleted && onCompleted(data?.data as Nullable<T>);

         if (!!data?.data) {
            const userData = transformUserData(data?.data);
            dispatch(addUser(userData));
            dispatch(addToken(localStorage.getItem(ENV.REACT_APP_TOKEN_KEY) as string));

            const page = STRUCTURE.find(
               (page) =>
                  page.roles?.includes(data?.data.role.name) && page.canBeDefaultPage,
            );
            dispatch(addDefaultPage(page?.path || DEFAULT_ROUTE));

            if (!localStorage.getItem(LANG))
               localStorage.setItem(LANG, AvailableLanguages.PT);
         }
      },
      onError: () => {
         dispatch(logout());
      },
   });

   return {
      getMe,
      loading,
      data,
      error,
   };
};

export { useIitMe };
