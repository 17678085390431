import { PortalPage } from 'utils/interfaces';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

export const STRUCTURE: PortalPage[] = [
   {
      id: 'Page_Companies',
      name: 'Companies',
      path: '/empresas',
      layout: 'layout_100',
      roles: ['ADMIN'],
      title: 'GENERAL.COMPANIES',
      isPublic: false,
      canBeDefaultPage: true,
      components: [
         {
            position: 0,
            location: 'components/generic-list',
            disableTitle: false,
            config: {
               _id: 'company_list',
               name: 'Company list',
               url: 'companies',
               internalUrl: true,
               selectableRows: true,
               listKeys: {
                  list: 'data',
                  listInfo: {
                     key: 'list',
                     step: 'page',
                     limit: 'pageSize',
                     total: 'total',
                  },
               },
               dataToTransform: [
                  {
                     label: 'ID',
                     key: 'id',
                     newKey: 'id',
                     type: 'text',
                  },
                  {
                     label: 'COMPONENT_COMPANIES.NAME',
                     key: 'name',
                     newKey: 'name',
                     type: 'text',
                     showAsColumn: true,
                  },
                  {
                     label: 'COMPONENT_COMPANIES.EMAIL',
                     key: 'email',
                     newKey: 'email',
                     type: 'text',
                     showAsColumn: true,
                  },
                  {
                     label: 'COMPONENT_COMPANIES.VAT',
                     key: 'nif',
                     newKey: 'nif',
                     type: 'text',
                     showAsColumn: true,
                  },
               ],
               detail: 'empresas-detalhe',
               pubSub: {
                  filters: {
                     name: 'CompaniesFilters',
                     variables: [
                        {
                           key: 'term',
                           newKey: 'term',
                        },
                     ],
                  },
               },
               headerButton: {
                  label: 'GENERAL.CREATE',
                  variant: 'contained',
                  action: {
                     openNewPage: false,
                  },
                  component: {
                     location: 'components/companies/companies-create',
                     title: 'COMPONENT_COMPANIES.COMPANY_CREATE',
                  },
               },
               datagridToolbarComponent: 'components/companies/companies-toolbar',
               headerFiltersComponent: 'components/companies/companies-filters',
            },
         },
      ],
   },
   {
      id: 'Page_CompaniesDetails',
      name: 'CompaniesDetails',
      path: '/empresas-detalhe',
      layout: 'layout_100',
      roles: ['ADMIN'],
      title: 'GENERAL.COMPANY_DETAIL',
      isPublic: false,
      components: [
         {
            position: 0,
            location: 'components/companies/companies-detail',
         },
         {
            position: 1,
            location: 'components/generic-list',
            pageTitle: 'GENERAL.PROJECTS',
            config: {
               _id: 'projects_list',
               name: 'Projects list',
               url: 'projects/company',
               internalUrl: true,
               selectableRows: true,
               paramId: {
                  key: 'companyId',
               },
               listKeys: {
                  list: 'data',
                  listInfo: {
                     key: 'list',
                     step: 'page',
                     limit: 'pageSize',
                     total: 'total',
                  },
               },
               dataToTransform: [
                  {
                     label: 'ID',
                     key: 'id',
                     newKey: 'id',
                     type: 'text',
                  },
                  {
                     label: 'COMPONENT_PROJECTS.NAME',
                     key: 'name',
                     newKey: 'name',
                     type: 'text',
                     showAsColumn: true,
                  },
                  {
                     label: 'COMPONENT_PROJECTS.TYPE',
                     key: 'type',
                     newKey: 'type',
                     type: 'tag',
                     showAsColumn: true,
                     color: {
                        default: '#00FFFF',
                        NEW_PROJECT: '#EAFBE7',
                        REHABILITATION: '#FDF4DC',
                        EXISTING_BUILDING_PROTECTION: '#FCECEA',
                     },
                     translate: {
                        NEW_PROJECT: 'COMPONENT_PROJECTS.TYPE_TRANS.NEW_PROJECT',
                        REHABILITATION: 'COMPONENT_PROJECTS.TYPE_TRANS.REHABILITATION',
                        EXISTING_BUILDING_PROTECTION:
                           'COMPONENT_PROJECTS.TYPE_TRANS.EXISTING_BUILDING_PROTECTION',
                     },
                  },
                  {
                     label: 'COMPONENT_PROJECTS.DATE',
                     key: 'createdAt',
                     newKey: 'createdAt',
                     type: 'date',
                     showAsColumn: true,
                  },
               ],
               rowsButtons: [
                  {
                     columnLabel: 'Actions',
                     icon: NoteAddOutlinedIcon,
                     color: 'primary',
                     action: {
                        openNewPage: true,
                        newPageRoute: 'calculadora',
                        navigateWithRowId: true,
                     },
                     secondColumnLabel: 'Actions',
                     secondIcon: DeleteOutlineOutlinedIcon,
                     secondColor: 'primary',
                     secondAction: {
                        openNewPage: false,
                        openModal: true,
                     },
                  },
               ],
               detail: 'projetos-detalhe',
               pubSub: {
                  filters: {
                     name: 'ProjectsFilters',
                     variables: [
                        {
                           key: 'company',
                           newKey: 'company',
                        },
                        {
                           key: 'type',
                           newKey: 'type',
                        },
                        {
                           key: 'name',
                           newKey: 'name',
                        },
                        {
                           key: 'filterBy',
                           newKey: 'filterBy',
                        },
                     ],
                  },
               },
               headerFiltersComponent: 'components/projects/projects-filters',
               datagridToolbarComponent: 'components/projects/projects-toolbar',
            },
         },
         {
            position: 2,
            location: 'components/users/users-list',
            pageTitle: 'GENERAL.USERS',
         },
      ],
   },
   {
      id: 'Page_Projects',
      name: 'Projects',
      path: '/projetos',
      layout: 'layout_100',
      roles: ['ADMIN', 'CLIENT'],
      title: 'GENERAL.PROJECTS',
      isPublic: false,
      canBeDefaultPage: true,
      components: [
         {
            position: 0,
            location: 'components/generic-list',
            disableTitle: false,
            config: {
               _id: 'project_list',
               name: 'Project list',
               url: `projects`,
               internalUrl: true,
               selectableRows: true,
               listKeys: {
                  list: 'data',
                  listInfo: {
                     key: 'list',
                     step: 'page',
                     limit: 'pageSize',
                     total: 'total',
                  },
               },
               dataToTransform: [
                  {
                     label: 'ID',
                     key: 'id',
                     newKey: 'id',
                     type: 'text',
                  },
                  {
                     label: 'OWNER_ID',
                     key: 'owner.id',
                     newKey: 'ownerId',
                     type: 'text',
                  },
                  {
                     label: 'COMPONENT_PROJECTS.NAME',
                     key: 'name',
                     newKey: 'name',
                     type: 'text',
                     showAsColumn: true,
                  },
                  {
                     label: 'COMPONENT_PROJECTS.COMPANY',
                     key: 'company.name',
                     newKey: 'companyName',
                     type: 'text',
                     showAsColumn: true,
                  },
                  {
                     label: 'COMPONENT_PROJECTS.TYPE',
                     key: 'type',
                     newKey: 'type',
                     type: 'tag',
                     showAsColumn: true,
                     color: {
                        default: '#00FFFF',
                        NEW_PROJECT: '#EAFBE7',
                        REHABILITATION: '#FDF4DC',
                        EXISTING_BUILDING_PROTECTION: '#FCECEA',
                     },
                     translate: {
                        NEW_PROJECT: 'COMPONENT_PROJECTS.TYPE_TRANS.NEW_PROJECT',
                        REHABILITATION: 'COMPONENT_PROJECTS.TYPE_TRANS.REHABILITATION',
                        EXISTING_BUILDING_PROTECTION:
                           'COMPONENT_PROJECTS.TYPE_TRANS.EXISTING_BUILDING_PROTECTION',
                     },
                  },
                  {
                     label: 'COMPONENT_PROJECTS.DATE',
                     key: 'createdAt',
                     newKey: 'createdAt',
                     type: 'date',
                     showAsColumn: true,
                  },
               ],
               rowsButtons: [
                  {
                     columnLabel: 'GENERAL.ACTIONS',
                     icon: NoteAddOutlinedIcon,
                     color: 'primary',
                     action: {
                        openNewPage: true,
                        newPageRoute: 'calculadora',
                        navigateWithRowId: true,
                     },
                     secondColumnLabel: 'GENERAL.ACTIONS',
                     secondIcon: DeleteOutlineOutlinedIcon,
                     secondColor: 'primary',
                     secondAction: {
                        openNewPage: false,
                        openModal: true,
                     },
                  },
               ],
               detail: 'projetos-detalhe',
               headerButton: {
                  label: 'GENERAL.CREATE',
                  variant: 'contained',
                  action: {
                     openNewPage: false,
                  },
                  component: {
                     location: 'components/projects/projects-create',
                     title: 'COMPONENT_PROJECTS.CREATE_PROJECT',
                  },
               },
               pubSub: {
                  filters: {
                     name: 'ProjectsFilters',
                     variables: [
                        {
                           key: 'company',
                           newKey: 'company',
                        },
                        {
                           key: 'type',
                           newKey: 'type',
                        },
                        {
                           key: 'name',
                           newKey: 'name',
                        },
                        {
                           key: 'filterBy',
                           newKey: 'filterBy',
                        },
                     ],
                  },
               },
               headerFiltersComponent: 'components/projects/projects-filters',
               datagridToolbarComponent: 'components/projects/projects-toolbar',
            },
         },
      ],
   },
   {
      id: 'Page_ProjectsDetails',
      name: 'ProjectsDetails',
      path: '/projetos-detalhe',
      layout: 'layout_100',
      roles: ['ADMIN', 'CLIENT'],
      title: 'GENERAL.PROJECTS_DETAIL',
      isPublic: false,
      components: [
         {
            position: 0,
            location: 'components/projects/projects-detail',
         },
         {
            position: 1,
            location: 'components/generic-list',
            pageTitle: 'GENERAL.CALCS',
            config: {
               _id: 'calcsList',
               name: 'Calcs list',
               url: 'project/calculations',
               internalUrl: true,
               selectableRows: true,
               paramId: {
                  key: 'projectId',
               },
               listKeys: {
                  list: 'data',
               },
               dataToTransform: [
                  {
                     label: 'ID',
                     key: 'stepGroupId',
                     newKey: 'id',
                     type: 'text',
                     showAsColumn: false,
                  },
                  {
                     label: 'GENERAL.IDENTIFIER',
                     key: 'identifier',
                     newKey: 'identifier',
                     type: 'text',
                     showAsColumn: true,
                  },
                  {
                     label: 'COMPONENT_PROJECTS.DETAIL.DST',
                     key: 'dst',
                     newKey: 'dst',
                     type: 'tag',
                     showAsColumn: true,
                     color: {
                        1: '#EAFBE7',
                        0.01: '#FDF4DC',
                        0.02: '#FDF4DC',
                        0.05: '#FDF4DC',
                     },
                     translate: {
                        1: 'COMPONENT_PROJECTS.DETAIL.NO',
                        0.01: 'COMPONENT_PROJECTS.DETAIL.LEVEL_I',
                        0.02: 'COMPONENT_PROJECTS.DETAIL.LEVEL_II',
                        0.05: 'COMPONENT_PROJECTS.DETAIL.LEVEL_III/IV',
                     },
                  },
                  {
                     label: 'COMPONENT_PROJECTS.DETAIL.SPDA',
                     key: 'spda',
                     newKey: 'spda',
                     type: 'tag',
                     showAsColumn: true,
                     color: {
                        1: '#EAFBE7',
                        0.2: '#FDF4DC',
                        0.1: '#FDF4DC',
                        0.05: '#FDF4DC',
                        0.02: '#FDF4DC',
                        0.01: '#FDF4DC',
                        0.001: '#FDF4DC',
                     },
                     translate: {
                        1: 'COMPONENT_PROJECTS.DETAIL.NO',
                        0.2: 'COMPONENT_PROJECTS.DETAIL.LEVEL_IV',
                        0.1: 'COMPONENT_PROJECTS.DETAIL.LEVEL_III',
                        0.05: 'COMPONENT_PROJECTS.DETAIL.LEVEL_II',
                        0.02: 'COMPONENT_PROJECTS.DETAIL.LEVEL_I',
                        0.01: 'COMPONENT_PROJECTS.DETAIL.LEVEL_I+',
                        0.001: 'COMPONENT_PROJECTS.DETAIL.LEVEL_I++',
                     },
                  },
                  {
                     label: 'COMPONENT_PROJECTS.DETAIL.DATE',
                     key: 'createdAt',
                     newKey: 'createdAt',
                     type: 'date',
                     showAsColumn: true,
                  },
               ],
               headerButton: {
                  label: 'GENERAL.NEW_CALC',
                  variant: 'contained',
                  action: {
                     openNewPage: true,
                     newPageRoute: 'calculadora',
                     navigateWithParamId: true,
                  },
               },
               detail: 'calculadora-detalhe',
               datagridToolbarComponent: 'components/calculator/calcs-toolbar',
            },
         },
      ],
   },
   {
      id: 'Page_RecoveryPassword',
      name: 'RecoveryPassword',
      path: '/recovery-password',
      layout: 'layout_100',
      roles: ['ADMIN'],
      title: 'GENERAL.RECOVERY_PASSWORD',
      isPublic: true,
      components: [
         {
            position: 0,
            location: 'components/login/recovery-password/recovery-password',
         },
      ],
   },
   {
      id: 'Pag_Calculator',
      name: 'Calculator',
      path: '/calculadora',
      layout: 'layout_100',
      roles: ['ADMIN', 'CLIENT'],
      title: 'GENERAL.CALCULATOR',
      isPublic: false,
      components: [
         {
            position: 0,
            location: 'components/calculator',
         },
      ],
   },
   {
      id: 'Page_CalcDetail',
      name: 'CalcDetail',
      path: '/calculadora-detalhe',
      layout: 'layout_100',
      roles: ['ADMIN', 'CLIENT'],
      title: 'GENERAL.CALC_DETAIL',
      isPublic: false,
      components: [
         {
            position: 0,
            location: 'components/calculator/calc-detail',
         },
      ],
   },
   {
      id: 'Page_CalcEdit',
      name: 'CalculatorEdit',
      path: '/calculadora-edicao',
      layout: 'layout_100',
      roles: ['ADMIN', 'CLIENT'],
      title: 'GENERAL.CALC_DETAIL',
      isPublic: false,
      components: [
         {
            position: 0,
            location: 'components/calculator',
         },
      ],
   },
   {
      id: 'Pag_Profile',
      name: 'Profile',
      path: '/perfil',
      layout: 'layout_100',
      roles: ['ADMIN', 'CLIENT'],
      title: 'GENERAL.PROFILE',
      isPublic: false,
      components: [
         {
            position: 0,
            location: 'components/profile',
         },
      ],
   },
   {
      id: 'Pag_SignUp',
      name: 'SignUp',
      path: '/signup',
      layout: 'layout_100',
      roles: [],
      title: 'GENERAL.SIGN_UP',
      isPublic: true,
      components: [
         {
            position: 0,
            location: 'components/sign-up',
         },
      ],
   },
];
