import React, { useMemo, useState } from 'react';

import { Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';

import { useIitGetObservableWithTopic } from 'hooks/iit-use-get-observable-with-topic';
import { ALERT } from 'utils/pubsub-topics';
import { Nullable } from 'utils/types';
import { setupTheme } from 'core/general-setup';
import { themeColors } from 'style/common';
import tinycolor from 'tinycolor2';
import { IAlertToaster } from 'utils/interfaces';
import { AlertModalSeverity } from 'utils/enums';

const AlertModal = () => {
   const [alertContent, setAlertContent] = useState<IAlertToaster>({
      open: false,
      message: '',
      severity: AlertModalSeverity.INFO,
      duration: null,
   });

   const theme = setupTheme();

   const ALERT_COLOR = useMemo(() => {
      switch (alertContent?.severity) {
         case 'success':
            return {
               background: themeColors.success,
               fontColor: themeColors.success,
            };
         case 'info':
            return {
               background: themeColors.neutral,
               fontColor: themeColors.neutral,
            };
         case 'warning':
            return {
               background: themeColors.warning,
               fontColor: themeColors.warning,
            };
         case 'error':
            return {
               background: themeColors.error,
               fontColor: themeColors.error,
            };
         default:
            return {
               background: themeColors.neutral,
               fontColor: themeColors.neutral,
            };
      }
   }, [alertContent?.severity]);

   useIitGetObservableWithTopic(ALERT, {
      onCompleted: (data: Nullable<IAlertToaster>) => {
         if (data) setAlertContent(data);
      },
   });

   return (
      <Snackbar
         anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         open={alertContent?.open}
         autoHideDuration={alertContent?.duration === null ? null : 2000}
         onClose={() => setAlertContent({ ...alertContent, open: false })}
      >
         <Alert
            elevation={6}
            variant="filled"
            severity={alertContent?.severity || 'info'}
            onClose={() => setAlertContent({ ...alertContent, open: false })}
            sx={{
               borderRadius: theme.spacing(0.5),
               backgroundColor: tinycolor(ALERT_COLOR?.background).lighten(65).toString(),
               color: ALERT_COLOR?.fontColor,
            }}
         >
            {alertContent?.message || ''}
         </Alert>
      </Snackbar>
   );
};

export default AlertModal;
