import React, { FC, ReactNode } from 'react';

import { Box, Modal } from '@mui/material';

import iitModalStyles from './style';

interface IIitModalProps {
   state: boolean;
   children: ReactNode;
   handleClose: () => void;
}

const IitModal: FC<IIitModalProps> = ({
   state,
   children,
   handleClose,
}: IIitModalProps) => {
   const { root } = iitModalStyles();

   return (
      <Modal open={state} onClose={handleClose} disableEnforceFocus>
         <Box className={root}>{children}</Box>
      </Modal>
   );
};

export { IitModal };
