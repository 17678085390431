import React, { Suspense, useState, useEffect, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Box, Container, Theme, useMediaQuery } from '@mui/material';

import MobileNavigation from 'components/mobile-navigation';
import { IitPubLoading } from 'components/shared-components/iit-pub-loading/iit-pub-loading';
import { showComponentMobile } from 'components/header/utils';
import Header from 'components/header';
import Sidebar from 'components/sidebar';
import useIitRouting from 'hooks/iit-use-routing';
import { useIitMe } from 'hooks/iit-use-me';
import { STRUCTURE } from 'structure';
import { logout } from 'store/reducers/user.reducer';
import { AlertModalSeverity, VIEW_MODES } from 'utils/enums';
import { displayToaster } from 'utils/utils';
import { IAlertToaster, PortalComponent, PortalPage } from 'utils/interfaces';

import { IitLayout } from '../components/iit-layout';
import { useAppDispatch, useAppSelector } from '../store';
import { collapsedDrawerWidth, drawerWidth, shellStyles } from '../style/common';
import Footer from 'components/footer';
import { IitCircularLoading } from 'components/shared-components/iit-loadings/iit-circular-loading/iit-circular-loading';
import { NAVIGATION_PATHS } from 'utils/routes';

function GetQueryParameter() {
   const { search } = useLocation();
   return useMemo(() => new URLSearchParams(search), [search]);
}

const Shell = () => {
   const [components, setComponents] = useState<PortalComponent[]>([]);
   const [isLoading, setIsLoading] = useState(true);
   const [drawerOpen, setDrawerOpen] = useState(true);
   const [page, setPage] = useState<PortalPage>({ isPublic: false });

   const dispatch = useAppDispatch();
   const {
      access_token,
      info: USER,
      defaultPage,
   } = useAppSelector((state) => state.user);
   const { pathname } = useLocation();
   const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
   const { navigateTo } = useIitRouting();
   const { t } = useTranslation();

   const queryViewMode = GetQueryParameter();
   const viewMode = queryViewMode.get('viewMode');
   const IS_WEB_VIEW_MODE = viewMode === VIEW_MODES.WEB_VIEW_MODE;

   const classes = shellStyles();

   useIitMe(!!USER || !page?.id || page.isPublic);

   const MENU_OPTIONS = useMemo(
      () =>
         [
            {
               name: 'SIDE_BAR.COMPANIES',
               url: `/${NAVIGATION_PATHS.COMPANIES}`,
               roles: ['ADMIN'],
               icon: 'group',
            },
            {
               name: 'SIDE_BAR.PROJECTS',
               url: `/${NAVIGATION_PATHS.PROJECTS}`,
               roles: ['ADMIN', 'CLIENT'],
               icon: 'fact_check',
            },
         ].filter((menu) => menu.roles.find((mRole) => USER?.roles.includes(mRole))),
      [USER?.roles],
   );

   const SHOW_BUTTON_NAVIGATION = useMemo(
      () =>
         isMobile &&
         !IS_WEB_VIEW_MODE &&
         showComponentMobile(pathname, IS_WEB_VIEW_MODE, isMobile),
      [IS_WEB_VIEW_MODE, isMobile, pathname],
   );

   const SHOW_SIDE_BAR = useMemo(
      () => !isMobile && !IS_WEB_VIEW_MODE && !page.isPublic && MENU_OPTIONS.length > 1,
      [IS_WEB_VIEW_MODE, MENU_OPTIONS.length, isMobile, page.isPublic],
   );

   const CONTAINER_WIDTH = useMemo(() => {
      if (IS_WEB_VIEW_MODE || page?.isPublic || !SHOW_SIDE_BAR) return '100%';

      return drawerOpen
         ? `calc(100% - ${drawerWidth})`
         : `calc(100% - ${collapsedDrawerWidth})`;
   }, [IS_WEB_VIEW_MODE, SHOW_SIDE_BAR, drawerOpen, page?.isPublic]);

   const CONTAINER_SX = useMemo(
      () => ({
         width: {
            sm: CONTAINER_WIDTH,
         },
         float: {
            sm: 'right',
         },
      }),
      [CONTAINER_WIDTH],
   );

   const loadViews = useCallback(async () => {
      try {
         const PAGE_CODE = pathname.split('/')[1];
         const PAGE = STRUCTURE.find((page) => page.path === `/${PAGE_CODE}`);
         if (!PAGE?.isPublic && USER) {
            const userToken = access_token;
            if (!PAGE || !PAGE?.roles?.length) {
               displayToaster({
                  message: t('GENERAL.ROUTE_DEFINED_WITHOUT_VIEW'),
                  severity: AlertModalSeverity.ERROR,
               } as IAlertToaster);
               return;
            }

            if (!USER?.roles) navigateTo(defaultPage);

            const role = PAGE?.roles.find((role: string) => USER?.roles.includes(role));
            if (!role) navigateTo(defaultPage);

            if (!userToken) dispatch(logout());
         }

         setPage(PAGE as PortalPage);
         const componentPromises: PortalComponent[] = _.compact(
            PAGE?.components?.map((comp) => ({ ...comp, pageId: PAGE?.id })),
         );
         Promise.all(componentPromises)
            .then(setComponents)
            .then(() => {
               setIsLoading(false);
            });
      } catch (error) {
         console.log(error);
      }
   }, [USER, access_token, dispatch, navigateTo, pathname, t, defaultPage]);

   useEffect(() => {
      loadViews();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [USER, pathname]);

   if (isMobile) return <></>;
   return (
      <React.Fragment>
         <IitPubLoading />

         <Suspense fallback={<IitCircularLoading />}>
            {!isLoading && (
               <React.Fragment>
                  <Header
                     isWebViewMode={IS_WEB_VIEW_MODE}
                     pageTitle={page?.title}
                     menuOptions={MENU_OPTIONS}
                     isPublicPage={page?.isPublic}
                  />
                  {SHOW_SIDE_BAR && (
                     <Sidebar
                        menuOptions={MENU_OPTIONS}
                        drawerOpen={drawerOpen}
                        setDrawerOpen={setDrawerOpen}
                     />
                  )}

                  <Box
                     display="flex"
                     flexDirection="column"
                     justifyContent="space-between"
                     // marginTop={8.875}
                     marginBottom={isMobile ? 7 : 0}
                  >
                     <Container className={classes.container} maxWidth={false}>
                        <Box display="flex" flexDirection="column" sx={CONTAINER_SX}>
                           <IitLayout
                              portalComponents={components}
                              isWebViewMode={IS_WEB_VIEW_MODE}
                              pageTitle={page?.title}
                              isPublicPage={page?.isPublic}
                              pageId={page.id as string}
                           />
                           {SHOW_BUTTON_NAVIGATION && <MobileNavigation />}
                        </Box>
                     </Container>
                  </Box>

                  <Footer />
               </React.Fragment>
            )}
         </Suspense>
      </React.Fragment>
   );
};

export { Shell };
