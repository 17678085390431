import React from 'react';

import Providers from 'components/Providers';
import AppNavigation from 'components/AppNavigation';

import { setupI18n } from './core/general-setup';

setupI18n();
export default function App() {
   return (
      <React.Fragment>
         <Providers>
            <AppNavigation />
         </Providers>
      </React.Fragment>
   );
}
