import dayjs from 'dayjs';
import { LIST_INFO_KEYS, PORTAL_ROLES } from './enums';
import { IListInfo, IPortalComponentConfig } from './interfaces';
import { NAVIGATION_PATHS } from './routes';

export const REQUIRED_FIELD = 'GENERAL.REQUIRED_FIELD';

export const LOADING_PUB_SUB_TOPIC = 'LOADING_PUBSUB_TOPIC';
export const UNLOADING_PUB_SUB_TOPIC = 'UNLOADING_PUBSUB_TOPIC';

export const REFRESH_CURRENT_POSITION = 'REFRESH_CURRENT_POSITION';

export const CURRENT_DATE = dayjs().toISOString();

export const TODAY_DATE_LESS_ONE_MONTH = dayjs().subtract(1, 'month').toISOString();
export const TODAY_DATE_LESS_THREE_MONTH = dayjs().subtract(3, 'month').toISOString();

export const DEFAULT_ROUTE = `/${NAVIGATION_PATHS.COMPANIES}`;

export const STATIC_IDENTIFIERS = {
   OPEN_DOCUMENT: {
      NAME: 'Open Document',
      ID: 'DOCS_OPEN_DOCUMENT',
   },
   CHANGE_ROUT: {
      NAME: 'Change Route',
      ID: 'WEB_CHANGE_ROUTE',
   },
};

export const ROLES_ACCESS = {
   ALL: [PORTAL_ROLES.ADMIN],
   ADMIN: [PORTAL_ROLES.ADMIN],
};

export const REGEXP = {
   HAS_NUMBER: /\d/,
   HAS_UPPERCASE: /[A-Z]/,
   HAS_SPECIAL_CHAR: /[^A-Za-z0-9]/,
};

export enum RN_WEBVIEW_EVENTS {
   CHANGE_LANGUAGE = 'change_language',
   LOGOUT = 'logout',
}

export const DEFAULT_LIST_INFO_KEYS = {
   key: LIST_INFO_KEYS.KEY,
   step: LIST_INFO_KEYS.STEP,
   limit: LIST_INFO_KEYS.LIMIT,
   total: LIST_INFO_KEYS.TOTAL,
};

export const DEFAULT_LIST_KEYS = {
   listInfo: DEFAULT_LIST_INFO_KEYS,
   list: undefined,
};

export const LIST_LIMIT = 10;

export const DEFAULT_PAGE_INFO: IListInfo = {
   step: 0,
   limit: LIST_LIMIT,
   total: 0,
};

export const DEFAULT_GENERIC_LIST_CONFIG: IPortalComponentConfig = {
   _id: '',
   name: '',
   url: '',
   dataToTransform: [],
   listKeys: {
      list: undefined,
      listInfo: {
         key: '',
         step: '',
         limit: '',
         total: '',
      },
   },
   pubSub: undefined,
   detail: undefined,
   internalUrl: false,
   selectableRows: false,
};

export const NO_CONTENT = 'N/A';
