import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const createCompanyStyles = makeStyles((theme: Theme) => ({
   header: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
         flexDirection: 'column-reverse',
      },
   },
   closeButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('md')]: {
         marginBottom: theme.spacing(2),
      },
   },
}));

export default createCompanyStyles;
