import { useState } from 'react';

const useIitDebounce = () => {
   const [timer, setTimer] = useState<NodeJS.Timeout>();

   const debounceSearch = (
      func?: () => void,
      term?: string,
      setTerm?: React.Dispatch<React.SetStateAction<string>>,
   ) => {
      clearTimeout(timer as NodeJS.Timeout);

      const bounce = setTimeout(() => {
         if (func) func();
         if (setTerm) setTerm(term ? term : '');
      }, 500);
      setTimer(bounce);
   };

   return [debounceSearch];
};

export { useIitDebounce };
