import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const headerUserMenuStyles = makeStyles((theme: Theme) => ({
   divider: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
   },
   input: {
      width: 'calc(100% - 60% + 0.625rem)',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
         width: '100%',
      },
   },
   button: {
      display: 'flex',
      marginLeft: 'auto',
      marginTop: '1em',
   },
   modalHeader: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
         flexDirection: 'column-reverse',
      },
   },
   modalCloseButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('md')]: {
         marginBottom: theme.spacing(2),
      },
   },
}));

export default headerUserMenuStyles;
