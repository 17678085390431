import React, { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import {
   Box,
   Button,
   Container,
   CssBaseline,
   Grid,
   Link,
   IconButton,
   Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { IitInput } from 'components/shared-components/iit-input';
import { IitModal } from 'components/shared-components/iit-modal/iit-modal';
import { useIitMe } from 'hooks/iit-use-me';
import { STRUCTURE } from 'structure';
import { useAppDispatch, useAppSelector } from 'store';
import { addDefaultPage, login, logout } from 'store/reducers/user.reducer';
import { DEFAULT_ROUTE, REQUIRED_FIELD } from 'utils/constants';
import { AlertModalSeverity, ComponentsSizes, InputType } from 'utils/enums';
import { IAlertToaster, ILoginResponse } from 'utils/interfaces';
import { displayToaster } from 'utils/utils';
import { Nullable } from 'utils/types';

import loginStyles from './styles';

import companyLogo from '../../assets/graphics/logoWithName.png';
import ENV from '../../config/environment';
import useCommonStyles from '../../style/common';
import { UserService } from 'services/user.service';
import { NAVIGATION_PATHS } from 'utils/routes';
import useIitRouting from 'hooks/iit-use-routing';
import { t } from 'i18next';
import ifcLogo from '../../assets/logo_ifc.png';
import { setupTheme } from 'core/general-setup';

enum LoginFormName {
   EMAIL = 'email',
   PASSWORD = 'password',
}

function Copyright() {
   const theme = setupTheme();

   return (
      <Box display="flex" alignItems="center" width={1}>
         <Box display="flex" flexGrow={1} alignItems="center">
            <Typography
               variant="body2"
               color="textSecondary"
               align="center"
               marginRight={1}
            >
               {'Copyright © '}
            </Typography>
            <Link href={ENV.REACT_APP_INFOCONTROL_URL} target="_blank" rel="noreferrer">
               <img src={ifcLogo} alt="ifc_logo" style={{ height: theme.spacing(8.5) }} />
            </Link>
         </Box>

         <Link
            color="textSecondary"
            href="https://www.bprotec.com/termos-de-utilizacao-software-arda/"
            target="_blank"
            rel="noreferrer"
         >
            <Typography variant="body2" color="textSecondary" align="center">
               {t('GENERAL.TERMS_AND_CONDITIONS')}
            </Typography>
         </Link>
      </Box>
   );
}

export default function Login() {
   const [loginLoading, setLoginLoading] = useState(false);
   const [modalRecoveryState, setModalRecoveryState] = useState(false);
   const [isSendingEmailRecover, setIsSendingEmailRecover] = useState(false);

   const {
      control: controlRecovery,
      handleSubmit: handleSubmitRecovery,
      reset,
   } = useForm();

   const navigate = useNavigate();
   const classes = loginStyles();
   const dispatch = useAppDispatch();
   const utilsClasses = useCommonStyles();
   const userState = useAppSelector((state) => state.user);
   const { t } = useTranslation();
   const { navigateTo } = useIitRouting();
   const { control, handleSubmit } = useForm();

   const location = useLocation();
   const searchParams = new URLSearchParams(location.search);
   const defaultEmail = searchParams.get('email');

   const getPages = useCallback(() => {
      if (userState?.info?.isPasswordGenerated) {
         displayToaster({
            message: t('AUTH.PLEASE_CHANGE_YOUR_PASSWORD'),
            severity: AlertModalSeverity.WARNING,
         } as IAlertToaster);
      }

      navigate(userState.defaultPage);
   }, [navigate, t, userState]);

   const { getMe } = useIitMe(true, {
      onCompleted: (user: Nullable<{ role: { name: string } }>) => {
         if (!user) return;

         const page = STRUCTURE.find(
            (page) => page.roles?.includes(user.role.name) && page.canBeDefaultPage,
         );
         dispatch(addDefaultPage(page?.path || DEFAULT_ROUTE));

         getPages();
      },
   });

   const handleRecoveryPassword = handleSubmitRecovery(
      useCallback(
         async ({ email }) => {
            setIsSendingEmailRecover(true);
            UserService.forgotPassword({
               email,
            })
               .then(() => {
                  setIsSendingEmailRecover(false);
                  setModalRecoveryState(false);
                  reset();
               })
               .catch(() => {
                  setIsSendingEmailRecover(false);
                  displayToaster({
                     message: t('GENERAL.ERROR'),
                     severity: AlertModalSeverity.ERROR,
                  } as IAlertToaster);
               });
         },
         [reset, t],
      ),
   );

   const submit = handleSubmit(
      useCallback(
         async ({ password, email }) => {
            setLoginLoading(true);
            const req = (await dispatch(login({ password, email }))) as ILoginResponse;
            setLoginLoading(false);

            if (!req?.payload) {
               displayToaster({
                  message: t(req?.error?.message || 'GENERAL.SOMETHING_WENT_WRONG'),
                  severity: AlertModalSeverity.ERROR,
               } as IAlertToaster);
               dispatch(logout());
               return null;
            }

            getMe();
         },
         [dispatch, getMe, t],
      ),
   );

   const loginKeyPress = useCallback(
      (ev: React.KeyboardEvent<HTMLDivElement>) => ev.code === 'Enter' && submit(),
      [submit],
   );

   useEffect(() => {
      dispatch(logout());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   if (userState.access_token) return <Navigate to={userState.defaultPage} />;
   return (
      <Container component="main" maxWidth="sm" className={utilsClasses.displayHeight}>
         <Grid
            className={utilsClasses.height100}
            container
            justifyContent="center"
            alignItems="center"
         >
            <CssBaseline />
            <div className={classes.paper}>
               <Link href={ENV.REACT_APP_BPROTEC_LINK} target="_blank" rel="noreferrer">
                  <img src={companyLogo} alt="Logo" className={classes.logo} />
               </Link>

               <Box className={classes.form}>
                  <Grid container spacing={2}>
                     <Grid item xs={12}>
                        <IitInput
                           name={LoginFormName.EMAIL}
                           label={t('GENERAL.EMAIL')}
                           defaultValue={defaultEmail ?? ''}
                           control={control}
                           className={classes.inputs}
                           rules={{
                              required: t('GENERAL.REQUIRED_FIELD') as string,
                              pattern: {
                                 value: ENV.REACT_APP_EMAIL_REGEX,
                                 message: t('GENERAL.INVALID_EMAIL_ADDRESS') as string,
                              },
                           }}
                           onKeyPress={loginKeyPress}
                        />
                     </Grid>
                     <Grid item xs={12}>
                        <IitInput
                           name={LoginFormName.PASSWORD}
                           label={t('GENERAL.PASSWORD')}
                           type={InputType.PASSWORD}
                           defaultValue=""
                           control={control}
                           className={classes.inputs}
                           rules={REQUIRED_FIELD}
                           onKeyPress={loginKeyPress}
                        />
                     </Grid>
                  </Grid>
                  <LoadingButton
                     loading={loginLoading}
                     disableElevation
                     size="large"
                     type="submit"
                     fullWidth
                     variant="contained"
                     color="primary"
                     className={classes.submit}
                     onClick={submit}
                  >
                     {t('GENERAL.LOGIN')}
                  </LoadingButton>
               </Box>

               <Box display="flex" justifyContent="center" alignItems="center" width={1}>
                  <Button
                     variant="text"
                     color="primary"
                     className={classes.recoveryPassword}
                     onClick={() => setModalRecoveryState(true)}
                  >
                     {t('GENERAL.FORGOT_YOUR_PASSWORD')}
                  </Button>
               </Box>
               <Box display="flex" justifyContent="center" alignItems="center" width={1}>
                  <Typography variant="body2" color="secondary">
                     {t('GENERAL.SIGN_UP_TEXT')}
                  </Typography>
                  <Button
                     variant="text"
                     color="primary"
                     className={classes.signUp}
                     onClick={() => navigateTo(`/${NAVIGATION_PATHS.SIGN_UP}`)}
                  >
                     {t('GENERAL.IS_SIGN_UP')}
                  </Button>
               </Box>
            </div>
            <Box width={1}>
               <Copyright />
            </Box>
            <IitModal
               state={modalRecoveryState}
               handleClose={() => setModalRecoveryState(false)}
            >
               <Box className={classes.modalHeader}>
                  <Typography variant="h5" flexGrow={1}>
                     {t('GENERAL.RECOVERY_PASSWORD')}
                  </Typography>
                  <IconButton
                     onClick={() => setModalRecoveryState(false)}
                     className={classes.modalCloseButton}
                  >
                     <CloseOutlinedIcon color="primary" />
                  </IconButton>
               </Box>
               <Typography variant="subtitle1" marginBottom={3}>
                  {t('GENERAL.MODAL_SUBTITLE')}
               </Typography>

               <IitInput
                  className={classes.inputRecovery}
                  name="email"
                  control={controlRecovery}
                  placeholder="E-mail"
                  size={ComponentsSizes.SMALL}
                  rules={{
                     required: t(REQUIRED_FIELD) as string,
                  }}
               />

               <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonRecovery}
                  onClick={handleRecoveryPassword}
                  disabled={isSendingEmailRecover}
               >
                  {isSendingEmailRecover
                     ? t('GENERAL.SENDING')
                     : t('GENERAL.RECOVERY_PASSWORD')}
               </Button>
            </IitModal>
         </Grid>
      </Container>
   );
}
