import { IitModal } from 'components/shared-components/iit-modal/iit-modal';
import React, { useCallback, useEffect, useMemo } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { IPropsTypesCreateCompany } from './create-company.types';
import createCompanyStyles from './create-company.styles';
import { ICreateCompany, UserService } from 'services/user.service';
import { InputType } from 'utils/enums';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { t } from 'i18next';
import { IitControlledInput } from 'components/shared-components/iit-controlled-input';
import { yupResolver } from '@hookform/resolvers/yup';
import { createCompanySchema } from './create-company.schema';
import { useForm } from 'react-hook-form';

const CreateCompany = ({
   isOpen,
   companyNif,
   handleClose,
   handleCheckCompany,
}: IPropsTypesCreateCompany) => {
   const { control, handleSubmit, reset, setValue } = useForm({
      resolver: yupResolver(createCompanySchema),
   });

   const classes = createCompanyStyles();

   const handleCreateCompany = handleSubmit(
      useCallback(
         async (data: any) => {
            if (!data) return;

            const body: ICreateCompany = {
               address: {
                  address: data.companyAddress,
                  city: data.companyCity,
                  country: data.companyCountry,
               },
               email: data.companyEmail,
               name: data.companyName,
               nif: data.companyNif,
               createdFrom: 'OUTSIDE_APP',
            };

            const companyCreated = await UserService.createCompany(body);

            if (companyCreated) {
               handleCheckCompany(data.companyNif);
               handleClose();
               reset();
            }
         },
         [handleCheckCompany, handleClose, reset],
      ),
   );

   const COMPANY_INPUTS = useMemo(
      () => [
         {
            name: 'companyName',
            label: 'GENERAL.COMPANY_NAME',
         },
         {
            name: 'companyNif',
            label: 'GENERAL.COMPANY_NIF',
            type: InputType.NUMBER,
            disabled: true,
         },
         {
            name: 'companyEmail',
            label: 'GENERAL.COMPANY_EMAIL',
         },
         {
            name: 'companyCountry',
            label: 'GENERAL.COMPANY_COUNTRY',
         },
         {
            name: 'companyCity',
            label: 'GENERAL.COMPANY_CITY',
         },
         {
            name: 'companyAddress',
            label: 'GENERAL.COMPANY_ADDRESS',
         },
      ],
      [],
   );

   useEffect(() => {
      setValue('companyNif', companyNif);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [companyNif]);

   return (
      <IitModal
         state={isOpen}
         handleClose={() => {
            reset();
            handleClose();
         }}
      >
         <Box className={classes.header}>
            <Typography variant="h5" flexGrow={1}>
               {t('GENERAL.CREATE_COMPANY')}
            </Typography>
            <IconButton
               onClick={() => {
                  reset();
                  handleClose();
               }}
               className={classes.closeButton}
            >
               <CloseOutlinedIcon color="primary" />
            </IconButton>
         </Box>
         <Typography variant="subtitle1" marginBottom={3}>
            {t('GENERAL.CREATE_COMPANY_SUBTITLE')}
         </Typography>

         {COMPANY_INPUTS.map(
            ({ name, label, disabled, type = InputType.TEXT }, index) => (
               <Box textAlign="center" marginBottom={3} key={index}>
                  <IitControlledInput
                     name={name}
                     control={control}
                     label={t(label)}
                     type={type}
                     disabled={disabled}
                  />
               </Box>
            ),
         )}

         <Box display="flex" justifyContent="end">
            <Button variant="contained" color="primary" onClick={handleCreateCompany}>
               {t('GENERAL.CREATE_COMPANY_BUTTON')}
            </Button>
         </Box>
      </IitModal>
   );
};

export default CreateCompany;
