import React, {
   ComponentType,
   lazy,
   LazyExoticComponent,
   useCallback,
   useEffect,
   useState,
} from 'react';
import { Navigate } from 'react-router-dom';

import { Box, Grid, Theme, useMediaQuery } from '@mui/material';

import { useAppSelector } from 'store';
import { ArrayString, IPortalComponentConfig, PortalComponent } from 'utils/interfaces';
import { findRole } from 'utils/utils';

import { generalSpacing, themeColors } from '../style/common';
import useIitRouting from 'hooks/iit-use-routing';
import { NAVIGATION_PATHS } from 'utils/routes';

interface IIitLayout {
   portalComponents: PortalComponent[];
   isWebViewMode: boolean;
   pageTitle?: string;
   isPublicPage?: boolean;
   pageId: string;
}

interface IComponents {
   view: LazyExoticComponent<ComponentType<any>>;
   location?: string;
   position?: number;
   routing?: ArrayString;
   path: string;
   showHeader: boolean;
   pageTitle?: string;
   isWebViewMode: boolean;
   width?: number;
   config?: IPortalComponentConfig;
   pageId: string;
}

const importView = (viewName?: string) => lazy(() => import(`../${viewName}`));

const IitLayout = ({
   portalComponents,
   isWebViewMode,
   pageTitle,
   isPublicPage,
   pageId,
}: IIitLayout) => {
   const [components, setComponents] = useState<IComponents[]>([]);

   const { info: USER, access_token } = useAppSelector((state) => state?.user);
   const spacing = generalSpacing();
   const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
   const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
   const { BASE_PATH: COMPONENT_PATH } = useIitRouting();

   const transformComponents = useCallback(() => {
      const plComponents = portalComponents.filter((component) => {
         if (!component.roles) return component;

         return findRole(component.roles, USER?.roles);
      });

      plComponents.forEach(async (comp: PortalComponent) => {
         const {
            location,
            position,
            routing,
            width,
            config,
            disableTitle,
            pageTitle: componentTitle,
            showHeader = true,
            pageId: compPageId,
         } = comp;

         const view = await importView(location);

         const component: IComponents = {
            location,
            position,
            routing,
            width,
            view,
            path: COMPONENT_PATH,
            showHeader: !showHeader
               ? false
               : (!isWebViewMode && !isMobile && !disableTitle) || !!isPublicPage,
            pageTitle: componentTitle || pageTitle,
            isWebViewMode,
            config,
            pageId,
         };

         setComponents((oldComp) => {
            const newComps = oldComp.filter((item) => item.pageId === compPageId);
            const index = newComps.findIndex(
               (cmp) => cmp.position === component.position,
            );
            if (index === -1) newComps.push(component);
            return newComps;
         });
      });
   }, [
      COMPONENT_PATH,
      isMobile,
      isPublicPage,
      isWebViewMode,
      pageId,
      pageTitle,
      portalComponents,
      USER?.roles,
   ]);

   // eslint-disable-next-line react-hooks/exhaustive-deps
   useEffect(() => transformComponents(), [pageId]);

   if (!access_token && !isPublicPage)
      return <Navigate to={`/${NAVIGATION_PATHS.LOGIN}`} />;
   return (
      <Box
         className={spacing.root}
         display="flex"
         flexGrow="1"
         flexDirection="column"
         justifyContent={isMobile ? 'normal' : 'space-between'}
         style={{
            backgroundColor: themeColors.background,
            height: `calc(100vh - 8.125rem)`,
            overflow: 'auto',
         }}
      >
         <Grid
            container
            display="flex"
            flexDirection={!isMobile || !isTablet ? 'row' : 'column'}
            flexWrap="wrap"
            columnSpacing={isPublicPage ? 0 : 2}
         >
            {components.length > 0 &&
               components
                  .sort((a: any, b: any) => {
                     return a.position - b.position;
                  })
                  .map((component: any, index: number) => {
                     const Component = component.view;
                     return (
                        <Grid
                           item
                           width={
                              component?.width && !isMobile && !isTablet && !isPublicPage
                                 ? `${component?.width}%`
                                 : '100%'
                           }
                           key={index}
                        >
                           <Component {...component} />
                        </Grid>
                     );
                  })}
         </Grid>
      </Box>
   );
};

export { IitLayout };
