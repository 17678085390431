import { Navigate } from 'react-router-dom';

import NotFound from 'components/404';
import Login from 'components/login';
import SignUp from 'components/sign-up';

export interface IRoute {
   path: string;
   element?: JSX.Element;
}

export enum NAVIGATION_PATHS {
   LOGIN = 'login',
   PROFILE = 'perfil',
   GENERIC_LIST = 'generic-list',
   NOT_FOUND = '404',
   PROJECTS = 'projetos',
   PROJECTS_DETAIL = 'projetos-detalhe',
   COMPANIES = 'empresas',
   COMPANIES_DETAIL = 'empresas-detalhe',
   RECOVERY_PASSWORD = 'recovery-password',
   CALCULATOR = 'calculadora',
   CALCULATOR_EDIT = 'calculadora-edicao',
   CALCULATOR_DETAIL = 'calculadora-detalhe',
   SIGN_UP = 'signup',
}

export const ROUTES: IRoute[] = [
   { path: `/${NAVIGATION_PATHS.LOGIN}`, element: <Login /> },
   { path: `/${NAVIGATION_PATHS.NOT_FOUND}`, element: <NotFound /> },
   { path: `/${NAVIGATION_PATHS.SIGN_UP}`, element: <SignUp /> },

   { path: `/${NAVIGATION_PATHS.GENERIC_LIST}` },
   { path: `/${NAVIGATION_PATHS.PROFILE}` },
   { path: `/${NAVIGATION_PATHS.COMPANIES}` },
   { path: `/${NAVIGATION_PATHS.COMPANIES_DETAIL}/:companyId` },
   { path: `/${NAVIGATION_PATHS.PROJECTS}` },
   { path: `/${NAVIGATION_PATHS.PROJECTS_DETAIL}/:projectId` },
   { path: `/${NAVIGATION_PATHS.RECOVERY_PASSWORD}` },
   { path: `/${NAVIGATION_PATHS.CALCULATOR}` },
   { path: `/${NAVIGATION_PATHS.CALCULATOR}/:projectId` },
   { path: `/${NAVIGATION_PATHS.CALCULATOR_EDIT}/:projectId/:stepGroupId` },
   {
      path: `/${NAVIGATION_PATHS.CALCULATOR_DETAIL}/:stepGroupId`,
   },

   { path: '*', element: <Navigate to={`/${NAVIGATION_PATHS.NOT_FOUND}`} replace /> },
   { path: '/', element: <Navigate to={`/${NAVIGATION_PATHS.LOGIN}`} replace /> },
];
