export enum InputType {
   PASSWORD = 'password',
   TEXT = 'text',
   EMAIL = 'email',
   NUMBER = 'number',
   DATE = 'date',
   FILE = 'file',
}

export enum AvailableLanguages {
   PT = 'pt',
   EN = 'en',
}

export enum ComponentsSizes {
   SMALL = 'small',
   MEDIUM = 'medium',
}

export enum DataGridDensity {
   COMPACT = 'compact',
   STANDARD = 'standard',
   COMFORTABLE = 'comfortable',
}

export enum HttpClientMethods {
   GET = 'GET',
   POST = 'POST',
   PUT = 'PUT',
   DELETE = 'DELETE',
   PATCH = 'PATCH',
}

export enum HookState {
   IDLE = 'idle',
   FETCHING = 'fetching',
   FETCHED = 'fetched',
   DELETING = 'deleting',
   DELETED = 'deleted',
   CREATING = 'creating',
   CREATED = 'created',
}

export enum ColorsStatus {
   SUCCESS = 'success',
   ERROR = 'error',
   WARNING = 'warning',
   INFO = 'info',
   PRIMARY = 'primary',
   SECONDARY = 'secondary',
   DEFAULT = 'default',
}

export enum Sort {
   ASC = 'asc',
   DESC = 'desc',
}

export enum Variants {
   BUTTON = 'button',
   CAPTION = 'caption',
   H1 = 'h1',
   H2 = 'h2',
   H3 = 'h3',
   H4 = 'h4',
   H5 = 'h5',
   H6 = 'h6',
   INHERIT = 'inherit',
   SUBTITLE1 = 'subtitle1',
   SUBTITLE2 = 'subtitle2',
   BODY1 = 'body1',
   BODY2 = 'body2',
   OVERLINE = 'overline',
}

export enum AlertModalSeverity {
   ERROR = 'error',
   WARNING = 'warning',
   INFO = 'info',
   SUCCESS = 'success',
}

export enum DEFAULT_FILTERS {
   ALL = 'ALL',
}

export enum PORTAL_ROLES {
   ADMIN = 'ADMIN',
   CLIENT = 'CLIENT',
}

export enum REDUCE_COUNT {
   QUANTITY = 'quantity',
   SUB_TOTAL = 'subTotal',
   RATE = 'rate',
}

export enum DOCUMENT_TYPE {
   PDF = 'PDF',
   IMAGE = 'IMAGE',
}

export enum AUTH_ENDPOINTS {
   LOGIN = '/auth/login',
   CHANGE_PASSWORD = '/auth/change-password',
   FORGOT_PASSWORD = '/auth/forgot-password',
   RESET_PASSWORD = '/auth/reset-password',
   CREATE_USER = '/auth/sign-up',
   CHECK_COMPANY = '/companies/checkCompany',
   CREATE_COMPANY = '/companies',
}

export enum EButtonVariant {
   TEXT = 'text',
   CONTAINED = 'contained',
   OUTLINED = 'outlined',
}

export enum MEASURABLES_EVENT_TYPES {
   COUNT = 'COUNT',
   NAVIGATION = 'NAVIGATION',
   OTHER = 'OTHER',
}

export enum WEEK_DAYS {
   MONDAY = 'monday',
   TUESDAY = 'tuesday',
   WEDNESDAY = 'wednesday',
   THURSDAY = 'thursday',
   FRIDAY = 'friday',
   SATURDAY = 'saturday',
   SUNDAY = 'sunday',
}

export enum VIEW_MODES {
   WEB_VIEW_MODE = 'webViewMode',
}

export enum LANGUAGE_TRANSLATION {
   PT = 'LANGUAGES.PT',
   EN = 'LANGUAGES.EN',
}

export enum LIST_INFO_KEYS {
   KEY = 'listInfo',
   STEP = 'step',
   LIMIT = 'limit',
   TOTAL = 'total',
}

export enum GENERIC_LIST_COLUMN_TYPE {
   TAG = 'tag',
   CURRENCY = 'currency',
   EXTERNAL_LINK = 'externalLink',
   DATE = 'date',
   PROGRESS_BAR = 'progressBar',
   TEXT = 'text',
}
