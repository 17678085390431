import { PortalComponentMenuOption } from 'utils/interfaces';

export enum SIDE_SHEET_TYPE {
   HEADER_PREFERENCES = 'HEADER_PREFERENCES',
   INVITE_USER = 'INVITE_USER',
}

export enum MENU_TYPE {
   USER = 'USER',
   MODULES = 'MODULES',
   MODULE_OPTIONS = 'MODULE_OPTIONS',
}

export interface IMenusState {
   type: MENU_TYPE;
   anchorEl: HTMLElement | null;
}

export interface ISideSheetState {
   type: SIDE_SHEET_TYPE;
   isVisible: boolean;
}

export interface INavbar {
   isWebViewMode: boolean;
   pageTitle?: string;
   menuOptions: PortalComponentMenuOption[];
   isPublicPage: boolean;
}
