const ENV = {
   REACT_APP_BRAND: process.env.REACT_APP_BRAND || 'Infocontrol',
   REACT_APP_BRAND_WEBSITE:
      process.env.REACT_APP_BRAND_WEBSITE || '"https://inflightit.com/"',
   REACT_APP_API_ENDPOINT:
      process.env.REACT_APP_API_ENDPOINT || 'http://localhost:1337/api',
   REACT_APP_EMAIL_REGEX:
      process.env.REACT_APP_EMAIL_REGEX || process.env.NODE_ENV === 'development'
         ? new RegExp(/^\w+([+.-]?\w+)*@\w+([.-]?\w+)(\.\w{2,3})+$/i)
         : new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)(\.\w{2,3})+$/i),
   REACT_APP_DATE_FORMAT: process.env.REACT_APP_DATE_FORMAT || 'DD/MM/YYYY',
   REACT_APP_TOKEN_KEY: process.env.REACT_APP_TOKEN_KEY || 'token',
   REACT_APP_SHORT_POLLING_DELAY: process.env.REACT_APP_SHORT_POLLING_DELAY || 30000,
   REACT_APP_SCHEDULER_LOCALE: process.env.SCHEDULER_LOCALE || 'pt-PT',
   REACT_APP_MEASURABLES_ENDPOINT:
      process.env.REACT_APP_MEASURABLES_ENDPOINT ||
      'http://localhost:3004/measurable/log-mesurables',
   MEASURABLES_CONFIG: {
      NAME: 'Authorezation',
      KEY: '99HY00TDGS8635PPTPIRWDMB1',
   },
   REACT_APP_ARDA_URL: process.env.REACT_APP_ARDA_URL || 'https://www.bprotec.com/arda',
   REACT_APP_CONTACTS_URL:
      process.env.REACT_APP_CONTACTS_URL || 'https://www.bprotec.com/contactos',
   REACT_APP_TERMS_AND_CONDITIONS_URL:
      process.env.REACT_APP_TERMS_AND_CONDITIONS_URL ||
      'https://www.bprotec.com/termos-de-utilizacao-software-arda',
   REACT_APP_INFOCONTROL_URL:
      process.env.REACT_APP_INFOCONTROL_URL || 'https://www.infocontrol.pt',
   REACT_APP_BPROTEC_MAIL: process.env.REACT_APP_BPROTEC_MAIL || 'bprotec@infocontrol.pt',
   REACT_APP_BPROTECT_THANK_YOU_PAGE_URL:
      process.env.REACT_APP_BPROTECT_THANK_YOU_PAGE_URL ||
      'https://www.bprotec.com/obrigado',
   REACT_APP_BPROTEC_LINK: process.env.REACT_APP_BPROTEC_LINK || 'https://www.bprotec.com'
};

export const PATHS = {
   LOGIN: '/auth/login',
   UPLOAD: '/upload',
};

export default ENV;
