import axios from 'axios';
import ENV from '../config/environment';
import { LOADING_PUB_SUB_TOPIC, UNLOADING_PUB_SUB_TOPIC } from 'utils/constants';
import { PubSub } from 'utils/pubsub';

const HTTP_CLIENT = axios.create({
   baseURL: ENV.REACT_APP_API_ENDPOINT,
});

HTTP_CLIENT.interceptors.request.use(function (config) {
   PubSub.publishWithTopic(LOADING_PUB_SUB_TOPIC);

   const token = localStorage.getItem(ENV.REACT_APP_TOKEN_KEY);
   config.headers.Authorization = token ? `Bearer ${token}` : '';

   return config;
});

HTTP_CLIENT.interceptors.response.use(function (config) {
   PubSub.publishWithTopic(UNLOADING_PUB_SUB_TOPIC);

   return config;
});

export default HTTP_CLIENT;
