import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const iitPubLoadingStyles = makeStyles((theme: Theme) => ({
   root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100vw',
      height: '100vh',
      zIndex: 9999999,
   },
}));

export default iitPubLoadingStyles;
