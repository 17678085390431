import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { themeColors } from '../../style/common';

const footerStyles = makeStyles((theme: Theme) => ({
   root: {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',

      height: theme.spacing(8.125),
      marginTop: 'auto',

      borderTop: `1px solid ${themeColors.divider}`,
      boxShadow: 'none',

      backgroundColor: themeColors.white,
   },
}));

export default footerStyles;
