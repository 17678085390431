import { useCallback, useMemo, useState, useEffect } from 'react';
import {
   Box,
   Typography,
   Button,
   LinearProgress,
   Checkbox,
   FormControlLabel,
   Link,
} from '@mui/material';
import { PasswordRules } from 'components/password-rules/PasswordRules';
import { IitControlledInput } from 'components/shared-components/iit-controlled-input';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { ICreateUserBody, UserService } from 'services/user.service';
import { displayToaster } from 'utils/utils';
import { AlertModalSeverity, InputType } from 'utils/enums';
import { IAlertToaster } from 'utils/interfaces';
import { themeColors } from 'style/common';

import { useIitDebounce } from 'hooks/iit-use-debounce';

import { signUpSchema } from './sign-up-schema';
import CreateCompany from './create-company/create-company';
import { IHandleSubmitData } from './sign-up.types';
import Header from './header/header';
import ENV from 'config/environment';

const SignUp = () => {
   const [arePasswordRulesValidated, setArePasswordRulesValidated] = useState(false);
   const [companyExists, setCompanyExists] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [modalCreateCompanyState, setModalCreateCompanyState] = useState(false);
   const [loadingSignUp, setLoadingSignUp] = useState(false);
   const [termsAndConditionsAreAccepted, setTermsAndConditionsAreAccepted] =
      useState(false);

   const {
      control,
      watch,
      setValue,
      handleSubmit,
      reset,
      formState: { isDirty },
   } = useForm({
      resolver: yupResolver(signUpSchema),
   });

   const { t } = useTranslation();

   const [debounce] = useIitDebounce();

   const handleCreateUser = handleSubmit(
      useCallback(
         async (data: IHandleSubmitData) => {
            setLoadingSignUp(true);
            if (!data) {
               setLoadingSignUp(false);
               return;
            }

            const {
               email,
               newPassword,
               cellphoneNumber,
               firstName,
               surname,
               companyNif,
            } = data;

            const resCompany = await UserService.checkCompany({ nif: companyNif });
            const resUser = await UserService.checkUser(email);

            if (resUser?.data) {
               setLoadingSignUp(false);
               return displayToaster({
                  message: t('GENERAL.ACCOUNT_EXISTS'),
                  severity: AlertModalSeverity.ERROR,
               } as IAlertToaster);
            }
            if (!resCompany?.data.id) {
               setLoadingSignUp(false);
               return displayToaster({
                  message: t('GENERAL.COMPANY_NULL'),
                  severity: AlertModalSeverity.ERROR,
               } as IAlertToaster);
            }

            const bodyCreateClient: ICreateUserBody = {
               username: email,
               email,
               password: newPassword,
               firstName,
               surname,
               cellphoneNumber,
               company: resCompany?.data.id,
            };

            const user = await UserService.createClient(bodyCreateClient);
            if (!user) {
               setLoadingSignUp(false);
               return;
            }

            reset();
            window.open(ENV.REACT_APP_BPROTECT_THANK_YOU_PAGE_URL, '_self');
            setLoadingSignUp(false);
         },
         [reset, t],
      ),
   );

   const handleCheckCompany = useCallback(
      async (nif: string) => {
         if (!nif) return;

         UserService.checkCompany({ nif }).then((res) => {
            if (!!res?.data) {
               setCompanyExists(true);
               setValue('companyName', res?.data.name);
               return;
            }

            setCompanyExists(false);
            setModalCreateCompanyState(true);
         });
      },
      [setValue],
   );

   const INPUTS = useMemo(
      () => [
         {
            name: 'firstName',
            label: 'GENERAL.FIRST_NAME',
            rules: { required: t('GENERAL.REQUIRED_FIELD') as string },
         },
         {
            name: 'surname',
            label: 'GENERAL.SURNAME',
            rules: { required: t('GENERAL.REQUIRED_FIELD') as string },
         },
         {
            name: 'email',
            label: 'GENERAL.EMAIL',
            rules: { required: t('GENERAL.REQUIRED_FIELD') as string },
         },
         {
            name: 'newPassword',
            label: 'GENERAL.NEW_PASSWORD',
            type: InputType.PASSWORD,
         },
         {
            name: 'newPasswordConfirmed',
            label: 'GENERAL.CONFIRM_NEW_PASSWORD',
            type: InputType.PASSWORD,
         },
         {
            name: 'cellphoneNumber',
            label: 'GENERAL.CELLPHONE',
            rules: { required: t('GENERAL.REQUIRED_FIELD') as string },
            inputProps: {
               maxLength: 9,
               onKeyPress: (e: React.KeyboardEvent) => {
                  if (!/[0-9]/.test(e.key)) {
                     e.preventDefault();
                  }
               },
            },
         },
      ],
      [t],
   );

   useEffect(() => {
      if (!watch('newPassword')) {
         setArePasswordRulesValidated(false);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [watch()]);

   return (
      <Box
         display="flex"
         flexDirection="column"
         alignItems="center"
         height={1}
         overflow="auto"
      >
         <Header />

         <Box
            display="flex"
            justifyContent="center"
            textAlign="center"
            flexDirection="column"
            maxWidth={900}
            marginY={2.5}
            marginX={{ xs: 2, sm: 8 }}
         >
            <Typography variant="h5" marginBottom={4}>
               {t('GENERAL.SIGN_UP_USER')}
            </Typography>

            <Box>
               {INPUTS.map(
                  ({ name, label, inputProps, rules, type = InputType.TEXT }, index) => (
                     <Box textAlign="center" marginBottom={3} key={index}>
                        <IitControlledInput
                           name={name}
                           control={control}
                           label={t(label)}
                           inputProps={inputProps}
                           type={type}
                           rules={rules}
                        />
                     </Box>
                  ),
               )}

               <Box marginBottom={3}>
                  <IitControlledInput
                     name="companyNif"
                     control={control}
                     label={t('GENERAL.COMPANY_NIF')}
                     rules={{ required: t('GENERAL.REQUIRED_FIELD') as string }}
                     inputProps={{
                        maxLength: 9,
                        onKeyPress: (e) => {
                           if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                           }
                        },
                     }}
                     cOnChange={(e) => {
                        if (e.target.value.length === 9) {
                           setIsLoading(true);
                           debounce(() => {
                              handleCheckCompany(e.target.value);
                              setIsLoading(false);
                           });
                        }
                     }}
                  />
                  {isLoading && (
                     <LinearProgress
                        sx={{
                           borderBottomLeftRadius: 20,
                           borderBottomRightRadius: 20,
                        }}
                     />
                  )}
               </Box>

               {companyExists && (
                  <IitControlledInput
                     name="companyName"
                     control={control}
                     label={t('GENERAL.COMPANY_NAME')}
                     type={InputType.TEXT}
                     disabled
                     sx={{ marginBottom: 3 }}
                  />
               )}

               <FormControlLabel
                  sx={{ width: 1 }}
                  label={
                     <Typography>
                        {t('GENERAL.ACCEPT_TERM_AND_CONDITIONS')}
                        <Link
                           color="inherit"
                           href={ENV.REACT_APP_TERMS_AND_CONDITIONS_URL}
                           target="_blank"
                           rel="noreferrer"
                        >
                           {t('GENERAL.CONDITIONS_POLICY_PRIVACY')}
                        </Link>
                        {t('GENERAL.OF_INFOCONTROL')}
                     </Typography>
                  }
                  control={
                     <Checkbox
                        checked={termsAndConditionsAreAccepted}
                        onChange={() =>
                           setTermsAndConditionsAreAccepted((state) => !state)
                        }
                        inputProps={{ 'aria-label': 'controlled' }}
                     />
                  }
               />

               {!!watch('newPassword') && !arePasswordRulesValidated && (
                  <Box textAlign="left" marginTop={3}>
                     <PasswordRules
                        password={watch('newPassword')}
                        onRulesChecked={(checked: boolean) =>
                           setArePasswordRulesValidated(checked)
                        }
                     />
                  </Box>
               )}
            </Box>

            <Box marginY={4}>
               <Button
                  disableElevation
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleCreateUser}
                  disabled={
                     !isDirty ||
                     !arePasswordRulesValidated ||
                     watch('newPassword') !== watch('newPasswordConfirmed') ||
                     !termsAndConditionsAreAccepted ||
                     loadingSignUp
                  }
               >
                  {loadingSignUp ? `${t('GENERAL.SIGN_UP')}...` : t('GENERAL.SIGN_UP')}
               </Button>
            </Box>

            <Typography color={themeColors.secondary}>
               {t('GENERAL.DISCLAIMER_SIGN_UP')}
            </Typography>
         </Box>

         <CreateCompany
            isOpen={modalCreateCompanyState}
            handleClose={() => {
               setModalCreateCompanyState(false);
            }}
            companyNif={watch('companyNif')}
            handleCheckCompany={handleCheckCompany}
         />
      </Box>
   );
};

export default SignUp;
