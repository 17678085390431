import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { Box, Button, Divider, IconButton, MenuItem, Typography } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { IitProfileImage } from 'components/shared-components/iit-profile-image/iit-profile-image';
import { IProfile } from 'utils/interfaces';
import { ComponentsSizes, PORTAL_ROLES } from 'utils/enums';
import { NAVIGATION_PATHS } from 'utils/routes';

import headerUserMenuStyles from './style';

import HeaderMenu from '../header-menu';
import { useAppDispatch } from 'store';
import { logout } from 'store/reducers/user.reducer';
import { sendEventToRnWebview } from 'utils/utils';
import { RN_WEBVIEW_EVENTS } from 'utils/constants';
import useIitRouting from 'hooks/iit-use-routing';
import { SIDE_SHEET_TYPE } from 'components/header/types';
import { IitControlledInput } from 'components/shared-components/iit-controlled-input';
import { IitModal } from 'components/shared-components/iit-modal/iit-modal';
import { UserService } from 'services/user.service';

interface IHeaderUserMenu {
   currentUser?: IProfile;
   anchorElUser: HTMLElement | null;
   handleCloseUserMenu: () => void;
   showHideSidesheet: (show?: boolean, type?: SIDE_SHEET_TYPE) => void;
}

export default function HeaderUserMenu({
   currentUser,
   anchorElUser,
   handleCloseUserMenu,
   showHideSidesheet,
}: IHeaderUserMenu) {
   const [modalInviteUserState, setModalInviteUserState] = useState(false);

   const { divider, modalHeader, modalCloseButton, input, button } =
      headerUserMenuStyles();
   const { t } = useTranslation();
   const { navigateTo } = useIitRouting();
   const dispatch = useAppDispatch();
   const {
      control: controlInviteUser,
      reset,
      formState: { isSubmitted },
      handleSubmit,
   } = useForm();

   const MENU_LIST = useMemo(
      () => [
         {
            text: 'HEADER.EDIT_PROFILE',
            handleClick: () => navigateTo(`/${NAVIGATION_PATHS.PROFILE}`),
         },
      ],
      [navigateTo],
   );

   const handleLogout = useCallback(() => {
      dispatch(logout());
      sendEventToRnWebview({
         type: RN_WEBVIEW_EVENTS.LOGOUT,
      });
      navigateTo(`/${NAVIGATION_PATHS.LOGIN}`);
   }, [dispatch, navigateTo]);

   const handleInviteUser = handleSubmit(
      useCallback(
         async (data) => {
            if (!data) return;
            UserService.inviteUser(data.email)
               .then(() => {
                  setModalInviteUserState(false);
                  reset();
               })
               .catch(() => {
                  setModalInviteUserState(false);
                  reset();
               });
         },
         [reset],
      ),
   );

   return (
      <HeaderMenu
         handleCloseMenu={handleCloseUserMenu}
         anchorEl={anchorElUser}
         sx={{ paddingX: 0 }}
      >
         <Box
            display="flex"
            alignItems="center"
            paddingY={1}
            paddingX={2}
            marginBottom={2}
         >
            <IitProfileImage
               image={currentUser?.image}
               name={currentUser?.fullName}
               color={
                  currentUser?.role.name === PORTAL_ROLES.ADMIN ? 'primary' : 'secondary'
               }
               sx={{ mr: 2 }}
            />
            <Box>
               <Typography variant="body1">{currentUser?.fullName}</Typography>
               <Typography variant="body2">{currentUser?.email}</Typography>
               <Typography variant="body2" color="grey">
                  {currentUser?.role.name === PORTAL_ROLES.ADMIN
                     ? t('GENERAL.ADMIN')
                     : t('GENERAL.CLIENT')}
               </Typography>
            </Box>
         </Box>

         {MENU_LIST.map(({ text, handleClick }, index: number) => (
            <Box key={index}>
               <MenuItem onClick={handleClick}>
                  <Typography textAlign="center">{t(text)}</Typography>
               </MenuItem>
            </Box>
         ))}

         <Divider className={divider} />
         <MenuItem onClick={handleLogout}>
            <Typography textAlign="center">{t('GENERAL.LOGOUT')}</Typography>
         </MenuItem>

         <IitModal
            state={modalInviteUserState}
            handleClose={() => {
               reset();
               setModalInviteUserState(false);
            }}
         >
            <Box className={modalHeader}>
               <Typography variant="h5" flexGrow={1}>
                  {t('COMPONENT_INVITE_USER.MODAL_TITLE')}
               </Typography>
               <IconButton
                  onClick={() => {
                     reset();
                     setModalInviteUserState(false);
                  }}
                  className={modalCloseButton}
               >
                  <CloseOutlinedIcon color="primary" />
               </IconButton>
            </Box>
            <Typography variant="subtitle1" marginBottom={3}>
               {t('COMPONENT_INVITE_USER.MODAL_SUBTITLE')}
            </Typography>

            <IitControlledInput
               className={input}
               name="email"
               control={controlInviteUser}
               placeholder={t('COMPONENT_INVITE_USER.EMAIL')}
               size={ComponentsSizes.SMALL}
            />

            <Button
               variant="contained"
               color="primary"
               className={button}
               disabled={isSubmitted}
               onClick={handleInviteUser}
            >
               {isSubmitted ? t('GENERAL.SENDING') : t('COMPONENT_INVITE_USER.INVITE')}
            </Button>
         </IitModal>
      </HeaderMenu>
   );
}
