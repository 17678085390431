import { NAVIGATION_PATHS } from 'utils/routes';

export const showComponentMobile = (
   path: string,
   isWebViewMode: boolean,
   isMobile: boolean,
) => {
   const PATH_MODIFIED = path.replace('/', '');

   // const DASHBOARD_PAGE = PATH_MODIFIED === NAVIGATION_PATHS.DASHBOARD;
   // const OPERATIONS_PAGE = PATH_MODIFIED === NAVIGATION_PATHS.OPERATIONS;
   const PROFILE_PAGE = PATH_MODIFIED === NAVIGATION_PATHS.PROFILE;

   if (!isWebViewMode && !isMobile) return true;

   return (isWebViewMode || isMobile) && PROFILE_PAGE;
};
