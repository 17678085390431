import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { createTheme } from '@mui/material/styles';

import translationPT from '../translations/pt.json';
import translationEN from '../translations/en.json';
import { BOX_SHADOW, themeColors } from '../style/common';
import { AvailableLanguages, LANGUAGE_TRANSLATION } from '../utils/enums';

export const LANG = 'lang';
export const DEFAULT_LANG = {
   name: LANGUAGE_TRANSLATION.PT,
   value: AvailableLanguages.PT,
};

export const setupI18n = () => {
   i18n.use(initReactI18next).init({
      resources: {
         pt: {
            translation: translationPT,
         },
         en: {
            translation: translationEN,
         },
      },
      lng: localStorage.getItem(LANG) || DEFAULT_LANG.value,
      fallbackLng: localStorage.getItem(LANG) || DEFAULT_LANG.value,
      interpolation: {
         escapeValue: false,
      },
   });
};

export const setupTheme = () => {
   const theme = createTheme();
   return createTheme({
      palette: {
         primary: {
            main: themeColors.primary,
            contrastText: themeColors.generalContrastText,
            light: themeColors.primaryLight,
         },
         secondary: {
            main: themeColors.secondary,
            light: themeColors.secondaryLight,
         },
         divider: themeColors.divider,
         background: {
            default: themeColors.background,
         },
      },
      typography: {
         fontFamily: 'Raleway',
         h1: {
            fontWeight: 'bold',
         },
         h2: {
            fontWeight: 'bold',
         },
         h3: {
            fontWeight: 'bold',
         },
         h4: {
            fontWeight: 'bold',
         },
         h5: {
            fontWeight: 'bold',
         },
         h6: {
            fontWeight: 'bold',
         },
      },
      components: {
         MuiButton: {
            styleOverrides: {
               root: {
                  textTransform: 'none',
                  boxShadow: 'none',
                  borderRadius: theme.spacing(1.875),
                  height: theme.spacing(5),
               },
            },
         },
         MuiPaper: {
            styleOverrides: {
               root: {
                  boxShadow: BOX_SHADOW,
                  backgroundColor: themeColors.backgroundSecondary,
               },
               rounded: {
                  borderRadius: theme.spacing(1.25),
               },
            },
         },
         MuiTooltip: {
            styleOverrides: {
               tooltip: {
                  fontSize: 14,
               },
            },
         },
      },
   });
};
