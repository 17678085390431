import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useRulesStyles = makeStyles((theme: Theme) => ({
   ul: {
      paddingLeft: theme.spacing(2),
      margin: 0,
      fontSize: '0.875rem',
   },
   invalid: {
      color: theme.palette.error.dark,
   },
   valid: {
      color: theme.palette.success.main,
   },
}));

export default useRulesStyles;
