import { t } from 'i18next';
import * as yup from 'yup';

const emailRules = /.+@.+\..+/;

export const createCompanySchema = yup.object().shape({
   companyNif: yup.string().required(t('COMPONENT_SCHEMA.REQUIRED')),
   companyName: yup.string().required(t('COMPONENT_SCHEMA.REQUIRED')),
   companyEmail: yup
      .string()
      .email(t('COMPONENT_SCHEMA.INVALID_EMAIL'))
      .matches(emailRules, t('COMPONENT_SCHEMA.INVALID_EMAIL'))
      .required(t('COMPONENT_SCHEMA.REQUIRED')),
   companyCountry: yup.string().required(t('COMPONENT_SCHEMA.REQUIRED')),
   companyCity: yup.string().required(t('COMPONENT_SCHEMA.REQUIRED')),
   companyAddress: yup.string().required(t('COMPONENT_SCHEMA.REQUIRED')),
});
