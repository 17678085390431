import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { Box } from '@mui/material';

import AlertModal from 'components/alerts/AlertModal';
import { Shell } from 'containers/shell';
import { themeColors } from 'style/common';
import { IRoute, ROUTES } from 'utils/routes';

const AppNavigation = () => {
   return (
      <>
         <BrowserRouter>
            <Box
               display="flex"
               flexDirection="column"
               justifyContent="start"
               style={{
                  backgroundColor: themeColors.background,
                  height: '100vh',
                  overflow: 'hidden',
               }}
            >
               <AlertModal />
               <Routes>
                  {ROUTES.map(({ path, element }: IRoute, index: number) => {
                     const component = element || <Shell />;
                     return <Route key={index} path={path} element={component} />;
                  })}
               </Routes>
            </Box>
         </BrowserRouter>
      </>
   );
};

export default AppNavigation;
