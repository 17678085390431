import { t } from 'i18next';
import * as yup from 'yup';
import { setupI18n } from 'core/general-setup';

const emailRules = /.+@.+\..+/;

setupI18n();

export const signUpSchema = yup.object().shape({
   firstName: yup.string().required(t('COMPONENT_SCHEMA.REQUIRED')),
   surname: yup.string().required(t('COMPONENT_SCHEMA.REQUIRED')),
   email: yup
      .string()
      .email(t('COMPONENT_SCHEMA.INVALID_EMAIL'))
      .matches(emailRules, t('COMPONENT_SCHEMA.INVALID_EMAIL'))
      .required(t('COMPONENT_SCHEMA.REQUIRED')),
   companyNif: yup
      .string()
      .required(t('COMPONENT_SCHEMA.REQUIRED'))
      .matches(/^[0-9]+$/, t('COMPONENT_SCHEMA.ONLY_NUMBERS'))
      .min(9, t('COMPONENT_SCHEMA.NIF_NOT_ENOUGH_NUMBERS'))
      .max(9, t('COMPONENT_SCHEMA.NIF_NOT_ENOUGH_NUMBERS')),
   cellphoneNumber: yup
      .string()
      .required(t('COMPONENT_SCHEMA.REQUIRED'))
      .matches(/^[0-9]+$/, t('COMPONENT_SCHEMA.ONLY_NUMBERS'))
      .min(9, t('COMPONENT_SCHEMA.CELLPHONE_NOT_ENOUGH_NUMBERS'))
      .max(9, t('COMPONENT_SCHEMA.CELLPHONE_NOT_ENOUGH_NUMBERS')),
   companyName: yup.string(),
   newPassword: yup.string().required(t('COMPONENT_SCHEMA.REQUIRED')),
   newPasswordConfirmed: yup
      .string()
      .oneOf([yup.ref('newPassword')], t('COMPONENT_SCHEMA.PASSWORDS_DONT_MATCH')),
});
