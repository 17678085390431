import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const loginStyles = makeStyles((theme: Theme) => ({
   logo: {
      width: theme.spacing(25),
      height: theme.spacing(25),
      objectFit: 'contain',
   },
   paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(8),
      width: '100%',
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
   },
   submit: {
      margin: theme.spacing(3, 0, 2),
      height: theme.spacing(7),
   },
   inputs: {
      marginBottom: theme.spacing(2),
      width: '100%',
   },
   recoveryPassword: {
      textDecoration: 'underline',
      '&:hover': {
         textDecoration: 'underline',
      },
   },
   signUp: {
      textDecoration: 'underline',
      '&:hover': {
         textDecoration: 'underline',
      },
   },
   inputRecovery: {
      width: 'calc(100% - 60% + 0.625rem)',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
         width: '100%',
      },
   },
   buttonRecovery: {
      display: 'flex',
      marginLeft: 'auto',
      marginTop: '1em',
   },
   modalHeader: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
         flexDirection: 'column-reverse',
      },
   },
   modalCloseButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('md')]: {
         marginBottom: theme.spacing(2),
      },
   },
}));

export default loginStyles;
