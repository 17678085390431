import React, { FC, ReactNode } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import {
   FormControl,
   InputBaseComponentProps,
   SxProps,
   TextField,
   TextFieldProps,
   Theme,
} from '@mui/material';

import useCommonStyles from '../../style/common';
import { ComponentsSizes, InputType } from '../../utils/enums';
import { InputSizeType } from '../../utils/types';

interface IitControlledInputProps {
   name: string;
   control: Control<any>;
   label?: string;
   rules?: any;
   defaultValue?: string | number;
   className?: string;
   disabled?: boolean;
   sx?: SxProps<Theme>;
   params?: TextFieldProps;
   inputProps?: InputBaseComponentProps;
   type?: InputType;
   cValue?: string;
   fullWidth?: boolean;
   placeholder?: string;
   cOnChange?: (e: any) => void;
   startAdornment?: ReactNode;
   endAdornment?: ReactNode;
   size?: InputSizeType;
   multiline?: boolean;
   rows?: number;
   onKeyPress?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
   key?: number | string;
   keyPress?: string;
}

const IitControlledInput: FC<IitControlledInputProps> = ({
   name,
   control,
   rules,
   label,
   defaultValue,
   className,
   disabled,
   params,
   type,
   inputProps,
   cValue,
   fullWidth = true,
   placeholder,
   cOnChange,
   startAdornment,
   endAdornment,
   size = ComponentsSizes.SMALL,
   multiline = false,
   rows,
   onKeyPress,
   keyPress,
   key,
   sx,
}: IitControlledInputProps) => {
   const { t } = useTranslation();
   const { width100 } = useCommonStyles();
   return (
      <FormControl className={clsx(fullWidth && width100)}>
         <Controller
            name={name}
            control={control}
            defaultValue={defaultValue ?? ''}
            rules={rules}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
               return (
                  <TextField
                     key={key}
                     sx={sx}
                     type={type ?? InputType.TEXT}
                     error={!!error}
                     name={name}
                     label={t(label || '')}
                     onChange={(e) => {
                        onChange(e);
                        cOnChange && cOnChange(e);
                     }}
                     value={cValue ? cValue : value}
                     className={className}
                     disabled={disabled}
                     {...params}
                     helperText={error && error?.message && (t(error?.message) as string)}
                     inputProps={inputProps as InputBaseComponentProps}
                     placeholder={placeholder}
                     InputProps={{
                        startAdornment,
                        endAdornment,
                     }}
                     size={size}
                     multiline={multiline}
                     rows={rows}
                     onKeyPress={(ev) => {
                        if (onKeyPress) {
                           if (keyPress && keyPress && ev.key === keyPress)
                              return onKeyPress(ev);

                           if (!keyPress) onKeyPress(ev);
                        }
                     }}
                  />
               );
            }}
         />
      </FormControl>
   );
};

export { IitControlledInput };
