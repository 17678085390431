import { AxiosError } from 'axios';
import { t } from 'i18next';
import { AlertModalSeverity, AUTH_ENDPOINTS } from 'utils/enums';
import { IAlertToaster } from 'utils/interfaces';
import { displayToaster } from 'utils/utils';
import HTTP_CLIENT from './http-client';

export interface IUserInvitePayload {
   phoneNumber: string;
   firstName: string;
   surname: string;
   email: string;
   company: string;
   birthDate: string;
   isAdmin: boolean;
}

export interface IChangePasswordPayload {
   currentPassword: string;
   password: string;
   passwordConfirmation: string;
}

export interface IForgotPasswordPayload {
   email: string;
}

export interface IResetPasswordPayload {
   password: string;
   passwordConfirmation: string;
   code: string | null;
}

export interface ICreateUserBody {
   username: string;
   email: string;
   password: string;
   firstName: string;
   surname: string;
   cellphoneNumber: string;
   company: number;
}

interface ICheckCompany {
   nif: string;
}

export interface ICreateCompany {
   address: {
      address: string;
      city: string;
      country: string;
   };
   email: string;
   name: string;
   nif: string;
   createdFrom: 'INSIDE_APP' | 'OUTSIDE_APP';
}

export const UserService = {
   changePassword: async (payload: IChangePasswordPayload) => {
      try {
         await HTTP_CLIENT.post(AUTH_ENDPOINTS.CHANGE_PASSWORD, {
            ...payload,
         });
         displayToaster({
            message: t('AUTH.PASSWORD_CHANGED_WITH_SUCCESS'),
            severity: AlertModalSeverity.SUCCESS,
         } as IAlertToaster);
      } catch (e: unknown) {
         throw (e as AxiosError)?.response?.data;
      }
   },
   forgotPassword: async (payload: IForgotPasswordPayload) => {
      try {
         await HTTP_CLIENT.post(AUTH_ENDPOINTS.FORGOT_PASSWORD, {
            ...payload,
         });
         displayToaster({
            message: t('GENERAL.CHECK_EMAIL'),
            severity: AlertModalSeverity.SUCCESS,
         } as IAlertToaster);
      } catch (e: unknown) {
         throw (e as AxiosError)?.response?.data;
      }
   },
   resetPassword: async (payload: IResetPasswordPayload) => {
      try {
         await HTTP_CLIENT.post(AUTH_ENDPOINTS.RESET_PASSWORD, {
            ...payload,
         });
         displayToaster({
            message: t('GENERAL.RESET_PASSWORD_SUCCESS'),
            severity: AlertModalSeverity.SUCCESS,
         } as IAlertToaster);
      } catch (e: unknown) {
         throw (e as AxiosError)?.response?.data;
      }
   },
   updateUser: async (
      payload: { [k: string]: string | number | boolean },
      userId: string,
   ) => {
      try {
         const { data } = await HTTP_CLIENT.put(`/users/${userId}`, {
            ...payload,
         });
         displayToaster({
            message: t('GENERAL.USER_UPDATED_WITH_SUCCESS'),
            severity: AlertModalSeverity.SUCCESS,
         } as IAlertToaster);
         return data;
      } catch (e: unknown) {
         throw (e as AxiosError)?.response?.data;
      }
   },
   approveUser: async (
      userId: number,
   ) => {
      try {
         const { data } = await HTTP_CLIENT.post(`/user/approve-company-account/${userId}`);
         displayToaster({
            message: t('GENERAL.USER_APPROVED_WITH_SUCCESS'),
            severity: AlertModalSeverity.SUCCESS,
         } as IAlertToaster);
         return data;
      } catch (e: unknown) {
         throw (e as AxiosError)?.response?.data;
      }
   },
   getUser: async () => {
      try {
         return await HTTP_CLIENT.get(`/users/me?populate=role,profileImage`);
      } catch (e: unknown) {
         throw (e as AxiosError)?.response?.data;
      }
   },
   getProjectsByUser: async (id: number) => {
      try {
         return await HTTP_CLIENT.get(`/projects/user/${id}`);
      } catch (e: unknown) {
         throw (e as AxiosError)?.response?.data;
      }
   },
   createClient: async (body: ICreateUserBody) => {
      try {
         return await HTTP_CLIENT.post(AUTH_ENDPOINTS.CREATE_USER, body);
      } catch (e: unknown) {
         displayToaster({
            message: t('GENERAL.SOMETHING_WENT_WRONG'),
            severity: AlertModalSeverity.ERROR,
         } as IAlertToaster);
      }
   },
   inviteUser: async (email: string) => {
      try {
         await HTTP_CLIENT.post('/user/invite-user', { email })
            .then(() => {
               displayToaster({
                  message: t('COMPONENT_INVITE_USER.SUCCESS'),
                  severity: AlertModalSeverity.SUCCESS,
               } as IAlertToaster);
            })
            .catch(() => {
               displayToaster({
                  message: t('COMPONENT_INVITE_USER.ERROR'),
                  severity: AlertModalSeverity.ERROR,
               } as IAlertToaster);
            });
      } catch (e: unknown) {
         displayToaster({
            message: t('GENERAL.SOMETHING_WENT_WRONG'),
            severity: AlertModalSeverity.ERROR,
         } as IAlertToaster);
      }
   },
   checkCompany: async (payload: ICheckCompany) => {
      try {
         return await HTTP_CLIENT.post(AUTH_ENDPOINTS.CHECK_COMPANY, {
            ...payload,
         });
      } catch (e: unknown) {
         displayToaster({
            message: t('GENERAL.SOMETHING_WENT_WRONG'),
            severity: AlertModalSeverity.ERROR,
         } as IAlertToaster);
      }
   },
   checkUser: async (email: string) => {
      try {
         return await HTTP_CLIENT.post('/user/check-user', {
            email,
         });
      } catch (e: unknown) {
         displayToaster({
            message: t('GENERAL.SOMETHING_WENT_WRONG'),
            severity: AlertModalSeverity.ERROR,
         } as IAlertToaster);
      }
   },
   createCompany: async (payload: ICreateCompany) => {
      return HTTP_CLIENT.post(AUTH_ENDPOINTS.CREATE_COMPANY, {
         ...payload,
      })
         .then((data) => {
            displayToaster({
               message: t('COMPONENT_COMPANIES.COMPANY_CREATED_SUCCESSFULLY'),
               severity: AlertModalSeverity.SUCCESS,
            } as IAlertToaster);

            return data;
         })
         .catch((error) => {
            displayToaster({
               message:
                  error?.response?.data?.error?.message ||
                  t('GENERAL.SOMETHING_WENT_WRONG'),
               severity: AlertModalSeverity.ERROR,
            } as IAlertToaster);

            return undefined;
         });
   },
   deleteUser: async () => {
      try {
         return await HTTP_CLIENT.post('/user/delete-current-user');
      } catch (e: unknown) {
         displayToaster({
            message: t('GENERAL.SOMETHING_WENT_WRONG'),
            severity: AlertModalSeverity.ERROR,
         } as IAlertToaster);
      }
   },
   validateRecoverPasswordCode: async (code: string) => {
      try {
         return await HTTP_CLIENT.get(`/user/validate-recover-password-code?code=${code}`);
      } catch (e) {
         displayToaster({
            message: t('GENERAL.SOMETHING_WENT_WRONG'),
            severity: AlertModalSeverity.ERROR,
         } as IAlertToaster);
      }

   }
};
