import { useCallback, useEffect, useState } from 'react';

import { httpClientRequest } from 'core/httpConfig';
import { AlertModalSeverity, HookState, HttpClientMethods } from 'utils/enums';
import { ArrayString, IAlertToaster, IListKeys, IRequestResult } from 'utils/interfaces';
import { Nullable } from 'utils/types';
import { displayToaster } from 'utils/utils';

const DEFAULT_DATA: IRequestResult = {
   data: [],
   listInfo: {
      step: 0,
      limit: 0,
      total: 0,
   },
};

interface IUseIitGet<T> {
   skip?: boolean;
   variables?: ArrayString;
   paramID?: string;
   internalUrl?: boolean;
   onCompleted?: (data: Nullable<T>) => void;
   onError?: (error: Nullable<T>) => void;
   listKeys?: IListKeys;
}

const getParams = (params?: ArrayString) => {
   if (!params) return;

   const obj: ArrayString = {};
   Object.keys(params).forEach((item) => {
      if (params[item] && item !== '_id') return (obj[item] = params[item]);
   });

   return obj;
};

export const useIitGet = <T>(
   url: string,
   {
      skip = false,
      variables,
      paramID,
      onCompleted,
      onError,
      internalUrl = true,
      listKeys,
   }: IUseIitGet<T> = {
      skip: false,
   },
) => {
   const [data, setData] = useState<IRequestResult>(DEFAULT_DATA);
   const [status, setStatus] = useState(HookState.IDLE);
   const [loading, setLoading] = useState(true);
   const [error, setError] = useState('');
   const [params, setParams] = useState<ArrayString | null>(null);

   const getRequest = useCallback(() => {
      if (variables) setParams(variables);
      setStatus(HookState.FETCHING);
      httpClientRequest({
         method: HttpClientMethods.GET,
         url,
         params: getParams(variables),
         paramID,
         internalUrl,
         listKeys,
      })
         .then((res: any) => {
            setData(res);
            onCompleted && onCompleted(res);
         })
         .catch((err) => {
            onCompleted && onCompleted(null);
            onError && onError(err);
            setError(err);
            displayToaster({
               message: err?.message,
               severity: AlertModalSeverity.ERROR,
            } as IAlertToaster);
         })
         .finally(() => {
            setLoading(false);
         });

      setStatus(HookState.FETCHED);
   }, [onCompleted, onError, paramID, url, variables, internalUrl, listKeys]);

   const refetch = useCallback(() => {
      getRequest();
   }, [getRequest]);

   useEffect(() => {
      if (!url || skip) return;
      if (JSON.stringify(variables) === JSON.stringify(params)) return;

      getRequest();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [url, variables, skip]);

   return {
      data,
      status,
      loading,
      error,
      refetch,
   };
};
