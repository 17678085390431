import { MutableRefObject } from 'react';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import {
   getHoursOfDate,
   numberToCurrency,
   transformMinutesToFullHours,
   getYearAndMonth as yearAndMonth,
   getStringInitials,
   truncateString as truncString,
} from 'iit-utils';

import { RN_WEBVIEW_EVENTS } from './constants';
import { REDUCE_COUNT } from './enums';
import { ArrayString, IAlertToaster, PortalComponent } from './interfaces';
import { PubSub } from './pubsub';
import { ALERT } from './pubsub-topics';

export const convertToNumber = (value: number | string) => {
   if (typeof value === 'string' && Number(value)) {
      return Number(value);
   }

   return value;
};

export const str2bool = (value: string | number) => {
   if (value && typeof value === 'string') {
      if (value.toLowerCase() === 'true') return true;
      if (value.toLowerCase() === 'false') return false;
   }
   return value;
};

export function dataGridDefaults() {
   // eslint-disable-next-line react-hooks/rules-of-hooks

   return {
      hideSortIcons: true,
      disableColumnMenu: true,
      minWidth: 200,
      width: 250,
      flex: 1,
   };
}

export const toFixed2Comma = (number: number) => numberToCurrency(number);

export const removeKeyFromObjectState = (key: string, { [key]: _, ...rest }) => rest;

export const reduceCount = (list: any[], property: REDUCE_COUNT) => {
   return list.reduce(
      (accumulator, currentValue) => accumulator + currentValue[property],
      0,
   );
};

export const importImage = (srcImage: string) => import(`../${srcImage}`);

export const importView = (view: PortalComponent) => {
   if (!view?.location) return;
   return import(`../${view.location}`).then((res) => {
      return {
         view: res.default,
         name: view?.location,
         position: view?.position,
         routing: view?.routing,
         menuOptions: view?.menuOptions,
      };
   });
};

export const getYearAndMonth = (date: string) => yearAndMonth(date);

export const getInitials = (text: string) => getStringInitials(text);

export const displayToaster = ({ message, severity, duration }: IAlertToaster) => {
   const toaster: IAlertToaster = {
      message,
      severity,
      open: true,
      duration,
   };

   PubSub.publishWithTopic(ALERT, 'error', toaster);
   return;
};

export const findRole = (roles?: string[], userRoles?: string[]) => {
   if (!userRoles || !roles) return false;
   return !!roles.find((role) => userRoles.includes(role));
};

export const scrollToBottom = (ref: MutableRefObject<HTMLDivElement | null>) => {
   ref?.current?.scrollIntoView({ behavior: 'smooth' });
};

export const genUniqueNumber = () => 'i' + new Date().getTime() + '';

export const parseDraftContentToRawJSON = (editorState: EditorState) => {
   return JSON.stringify(convertToRaw(editorState.getCurrentContent()));
};

export const createDraftContentFromRawJSON = (rawJSON: string) => {
   return EditorState.createWithContent(convertFromRaw(JSON.parse(rawJSON)));
};

export const truncateString = (text: string, length: number) => truncString(text, length);

export const getFulltimeFromMinutes = (minutes: number) =>
   transformMinutesToFullHours(minutes);

export const listByPage = (page: number, orders: unknown[]) => {
   const end = page * 10;
   const start = end - 9 - 1;
   return orders.slice(start, end);
};

export const csvFileToArray = (string: string) => {
   const csvHeader = string
      .slice(0, string.indexOf('\n'))
      .replaceAll('\r', '')
      .replaceAll(' ', '')
      .split(',');
   const csvRows = string
      .slice(string.indexOf('\n') + 1)
      .replaceAll('\r', '')
      .split('\n');

   return csvRows.map((i: string) => {
      const values = i.split(',');
      const obj = csvHeader.reduce(
         (object: ArrayString, header: string, index: number) => {
            object[header] = values[index];
            return object;
         },
         {},
      );
      return obj;
   });
};

/**
 *
 * @summary
 * Send information to parent view(react-native)
 */
export const sendEventToRnWebview = (payload: {
   value?: any;
   type: RN_WEBVIEW_EVENTS;
}) => {
   window?.ReactNativeWebView?.postMessage(JSON.stringify(payload));
};

export const getTime = (date?: Date | string | number) => date && getHoursOfDate(date);

export const getItemsDirtyData = (
   data: {
      [x: string]: any;
   },
   dirtyItems: Partial<
      Readonly<{
         [x: string]: any;
      }>
   >,
): Partial<{
   [x: string]: any;
}> => {
   const dirtyItemsEntries = Object.keys(dirtyItems);

   let body = {};

   dirtyItemsEntries.forEach((key) => (body = { ...body, [key]: data[key] }));

   return body;
};
