import axios from 'axios';

import ENV from '../config/environment';
import {
   DEFAULT_LIST_KEYS,
   LOADING_PUB_SUB_TOPIC,
   UNLOADING_PUB_SUB_TOPIC,
} from '../utils/constants';
import { PubSub } from 'utils/pubsub';
import { HttpClientMethods } from 'utils/enums';
import {
   ArrayString,
   IListKeys,
   IListKeysWithList,
   IRequestResultArray,
   IRequestResultObject,
} from 'utils/interfaces';

interface IHttpClientRequest {
   method: HttpClientMethods;
   url: string;
   params?: ArrayString;
   paramID?: string;
   body?: ArrayString;
   internalUrl?: boolean;
   listKeys?: IListKeys;
}

interface IGetUrl {
   url: string;
   paramID?: string;
   internalUrl?: boolean;
}

const API_URL = ENV.REACT_APP_API_ENDPOINT;

const getHeaders = () => {
   const userJWT = localStorage.getItem(ENV.REACT_APP_TOKEN_KEY);
   const userRefreshToken = localStorage.getItem('refreshToken');
   return {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      ...(userJWT && { Authorization: `Bearer ${userJWT}` }),
      Refresh: `${userRefreshToken}`,
   };
};

const getUrl = ({ url, paramID, internalUrl }: IGetUrl) => {
   if (internalUrl) return paramID ? `${API_URL}/${url}/${paramID}` : `${API_URL}/${url}`;

   return paramID ? `${url}/${paramID}` : url;
};

const isArray = (data: unknown) => Array.isArray(data);

const getDataWithListKeyField = (data: any, listKeys: IListKeysWithList) => {
   const { list, listInfo } = listKeys;

   const listInfoKey = listInfo?.key ? listInfo?.key : 'listInfo';

   return {
      data: data[listKeys?.list] || [],
      listInfo: {
         total:
            listInfoKey && listInfo?.total
               ? data?.[listInfoKey]?.[listInfo?.total]
               : data?.[list]?.length,
      },
   };
};

export const httpClientRequest = ({
   method = HttpClientMethods.GET,
   url = '',
   params = {},
   paramID = '',
   body = {},
   internalUrl = true,
   listKeys = DEFAULT_LIST_KEYS,
}: IHttpClientRequest) => {
   const requestUrl = getUrl({ url, paramID, internalUrl });

   PubSub.publishWithTopic(LOADING_PUB_SUB_TOPIC);

   return axios
      .request({
         method,
         url: requestUrl,
         headers: getHeaders(),
         params,
         responseType: 'json',
         data: body,
      })
      .then(({ data }: any) => {
         PubSub.publishWithTopic(UNLOADING_PUB_SUB_TOPIC);
         if (listKeys?.list)
            return getDataWithListKeyField(data, listKeys as IListKeysWithList);

         return isArray(data)
            ? ({
                 data: data,
                 listInfo: {
                    total: data?.length,
                 },
              } as IRequestResultArray)
            : ({ data: data } as IRequestResultObject);
      })
      .catch((error: unknown) => {
         PubSub.publishWithTopic(UNLOADING_PUB_SUB_TOPIC);
         throw error;
      });
};
