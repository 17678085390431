import React, { useMemo } from 'react';
import clsx from 'clsx';

import { Box, Link, Typography } from '@mui/material';

import footerStyles from './style';

import { drawerWidth, generalSpacing } from '../../style/common';
import { useTranslation } from 'react-i18next';
import ENV from 'config/environment';
import { setupTheme } from 'core/general-setup';
import ifcLogo from '../../assets/logo_ifc.png';
import { useAppSelector } from 'store';
import { PORTAL_ROLES } from 'utils/enums';

const ITEMS = [
   {
      label: 'GENERAL.ARDA',
      url: ENV.REACT_APP_ARDA_URL,
   },
   {
      label: 'GENERAL.CONTACTS',
      url: ENV.REACT_APP_CONTACTS_URL,
   },
   {
      label: 'GENERAL.TERMS_AND_CONDITIONS',
      url: ENV.REACT_APP_TERMS_AND_CONDITIONS_URL,
   },
];

const Footer = () => {
   const spacing = generalSpacing();
   const { root } = footerStyles();
   const { t } = useTranslation();
   const theme = setupTheme();

   const USER = useAppSelector((state) => state?.user?.info);

   const isAdmin = useMemo(() => USER?.roles.includes(PORTAL_ROLES.ADMIN), [USER?.roles]);

   return (
      <Box className={clsx(root, spacing.root)} component="footer">
         <Box display="flex" width={isAdmin ? `calc(100% - ${drawerWidth})` : 1}>
            <Box display="flex" alignItems="center" flexGrow={1}>
               {ITEMS?.map((item) => (
                  <Link
                     key={item?.url}
                     color="textSecondary"
                     href={item?.url}
                     sx={{ marginRight: 2 }}
                     target="_blank"
                     rel="noreferrer"
                  >
                     <Typography variant="body2" color="textSecondary" noWrap>
                        {t(item?.label)}
                     </Typography>
                  </Link>
               ))}
            </Box>

            <Box display="flex" alignItems="center">
               <Typography variant="body2" color="textSecondary" noWrap marginRight={1}>
                  Copyright ©
               </Typography>

               <Link
                  href={ENV.REACT_APP_INFOCONTROL_URL}
                  target="_blank"
                  rel="noreferrer"
               >
                  <img
                     src={ifcLogo}
                     alt="ifc_logo"
                     style={{ height: theme.spacing(8.5) }}
                  />
               </Link>
            </Box>
         </Box>
      </Box>
   );
};

export { Footer };
