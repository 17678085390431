import { FC } from 'react';
import clsx from 'clsx';

import { Box, SxProps, Theme } from '@mui/material';

import { getInitials } from 'utils/utils';

import profileImageStyles from './style';

type TSize = 'small' | 'medium' | 'large' | 'xLarge';

interface IProfileImageProps {
   name?: string;
   size?: TSize;
   color?: string;
   sx?: SxProps<Theme>;
   image?: string;
}

const IitProfileImage: FC<IProfileImageProps> = ({
   name,
   sx,
   image,
   size = 'medium',
   color = 'primary',
}) => {
   const profileImageStyle = profileImageStyles() as any;

   return (
      <Box
         className={clsx(
            profileImageStyle.profileImage,
            profileImageStyle[size],
            profileImageStyle[color],
         )}
         sx={sx}
      >
         {image ? (
            <img
               src={image}
               alt=""
               className={clsx(profileImageStyle.profileImage, profileImageStyle[size])}
               style={{ border: 0 }}
            />
         ) : (
            getInitials(name || '')
         )}
      </Box>
   );
};

export { IitProfileImage };
