import { useNavigate, useLocation } from 'react-router-dom';

/**
 *
 * @summary
 * UrlNumber type of navigate to back
 */
type UrlNumber = -1;

interface INavigateTo {
   _blank?: boolean;
}

export default function useIitRouting() {
   const navigate = useNavigate();
   const { pathname } = useLocation();

   const BASE_PATH = `${pathname.split('/')[1]}`;

   const navigateTo = (
      url: string | UrlNumber,
      { _blank }: INavigateTo = { _blank: false },
   ) => {
      const typeOfUrl = typeof url;

      if (typeOfUrl === 'number') return navigate(-1);

      if (_blank) return window.open(url as string, '_blank');

      return navigate(url as string);
   };

   return {
      navigateTo,
      BASE_PATH,
   };
}
