import React, { useState } from 'react';

import { useIitGetObservableWithTopic } from 'hooks/iit-use-get-observable-with-topic';

import { LOADING_PUB_SUB_TOPIC, UNLOADING_PUB_SUB_TOPIC } from '../../../utils/constants';
import { Box, LinearProgress } from '@mui/material';
import iitPubLoadingStyles from './style';

const IitPubLoading = () => {
   const [count, setCount] = useState(0);

   const iitLoadingStyle = iitPubLoadingStyles();

   useIitGetObservableWithTopic(LOADING_PUB_SUB_TOPIC, {
      onCompleted: () => setCount((pev) => pev + 1),
   });

   useIitGetObservableWithTopic(UNLOADING_PUB_SUB_TOPIC, {
      onCompleted: () => setCount((pev) => pev - 1),
   });

   if (count === 0) return <></>;
   return (
      <Box className={iitLoadingStyle.root}>
         <LinearProgress color="primary" sx={{ width: 1, position: 'fixed', top: 0 }} />
      </Box>
   );
};

export { IitPubLoading };
