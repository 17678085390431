import { CSSObject, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const drawerWidth = '15.625rem';
export const collapsedDrawerWidth = '5.063rem';

export const BOX_SHADOW =
   '0px 8px 12px 6px rgba(0, 0, 0, 0.02), 0px 4px 4px rgba(0, 0, 0, 0.04)';

export const themeColors = {
   primary: '#e05325',
   primaryLight: '#c2561c78',
   primaryEvenLighter: '#f4702929',
   secondary: '#6d6e71',
   secondaryLight: '#6d6e71ab',
   secondaryEvenLighter: '#6d6e7178',
   generalContrastText: '#fff',
   divider: 'rgb(223, 231, 235)',
   background: '#FBFBFB',
   backgroundSecondary: '#fff',
   white: '#fff',
   gray: '#C5C5C5',
   grayLight: '#e9e9eb',
   grayEvenLighter: '#f5f5f5',
   success: '#268115',
   warning: '#f9a825',
   error: '#820c0c',
   approved: '#EAFBE7',
   notApproved: '#FCECEA',
   neutral: '#414162',
   black: '#000',
   dark: '#252525',
   lightDark: '#575757',
   transparency: '#ffffff00',
   icons: '#0000008a',
   yellow: '#ffd600',
   link: '#4676F2',
   lightPurple: '#ECEAFD',
   cobalt: '#0147AB',
};

const useCommonStyles = makeStyles(() => ({
   displayHeight: {
      height: '100vh',
   },
   height100: {
      height: '100%',
   },
   width100: {
      width: '100%',
   },
}));

export const openedMixin = (theme: Theme): CSSObject => ({
   width: drawerWidth,
   transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
   }),
   overflowX: 'hidden',
});

export const closedMixin = (theme: Theme): CSSObject => ({
   transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
   }),
   overflowX: 'hidden',
   width: collapsedDrawerWidth,
});

export const drawerStyle = makeStyles((theme: Theme) => ({
   drawer: {
      width: drawerWidth,
      paddingTop: '0.563rem',
      paddingBottom: '0.563rem',
      height: 'calc(100% - 5rem)',
   },
}));

export const generalSpacing = makeStyles((theme: Theme) => ({
   root: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
   },
}));

export const shellStyles = makeStyles((theme: Theme) => ({
   container: {
      padding: 0,
      overflow: 'hidden',
   },
   drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
   },
}));

export const sxNonMobile = {
   display: {
      xs: 'none',
      md: 'flex',
   },
};

export const sxMobile = {
   display: {
      xs: 'flex',
      md: 'none',
   },
};

export const sxMobilePaddingTop = {
   paddingTop: {
      xs: 2,
      md: 0,
   },
};

export const tooltipStyle = makeStyles((theme: Theme) => ({
   root: {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
      cursor: 'pointer',
      color: '#7e7e7e',
   },
}));

export default useCommonStyles;
