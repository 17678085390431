import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { themeColors } from 'style/common';

const profileImageStyles = makeStyles((theme: Theme) => ({
   profileImage: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: theme.spacing(5),
      border: '0.063rem solid',
      objectFit: 'cover',
   },
   primary: {
      borderColor: themeColors.primary,
      backgroundColor: themeColors.primaryLight,
   },
   secondary: {
      borderColor: themeColors.secondary,
      backgroundColor: themeColors.secondaryLight,
   },
   primaryLightSolid: {
      borderColor: themeColors.primary,
      backgroundColor: '#bab2ff',
   },
   secondaryLightSolid: {
      borderColor: themeColors.secondary,
      backgroundColor: '#fac9fe',
   },
   small: {
      width: theme.spacing(3.375),
      height: theme.spacing(3.375),
      fontSize: theme.spacing(1.5),
   },
   medium: {
      width: theme.spacing(4.375),
      height: theme.spacing(4.375),
   },
   large: {
      width: theme.spacing(5.375),
      height: theme.spacing(5.375),
      fontSize: theme.spacing(2.5),
   },
   xLarge: {
      width: theme.spacing(25),
      height: theme.spacing(25),
      fontSize: theme.spacing(11.25),
      borderRadius: '50%',
   },
}));

export default profileImageStyles;
