import { Box, Toolbar } from '@mui/material';
import navbarStyles from 'components/header/style';
import useIitRouting from 'hooks/iit-use-routing';
import { generalSpacing, themeColors } from 'style/common';
import companyLogo from '../../../assets/graphics/logoSide.png';

const Header = () => {
   const { navigateTo } = useIitRouting();

   const { companyLogoStyle } = navbarStyles();
   const spacing = generalSpacing();
   return (
      <Box width={1} borderBottom={`1px solid ${themeColors.divider}`}>
         <Toolbar className={spacing.root}>
            <img
               className={companyLogoStyle}
               src={companyLogo}
               alt="Logo"
               onClick={() => navigateTo(-1)}
            />
         </Toolbar>
      </Box>
   );
};

export default Header;
