import React, { useCallback, useMemo, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
   AppBar,
   Box,
   IconButton,
   Theme,
   Toolbar,
   Typography,
   useMediaQuery,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { IitProfileImage } from 'components/shared-components/iit-profile-image/iit-profile-image';
import useIitRouting from 'hooks/iit-use-routing';
import { useAppSelector } from 'store';

import HeaderUserMenu from './components/user-menu/user-menu';
import navbarStyles from './style';
import {
   IMenusState,
   INavbar,
   ISideSheetState,
   MENU_TYPE,
   SIDE_SHEET_TYPE,
} from './types';
import { showComponentMobile } from './utils';

import companyLogo from '../../assets/graphics/logoSide.png';
import { generalSpacing, themeColors } from '../../style/common';
import { PORTAL_ROLES } from 'utils/enums';
import { setupTheme } from 'core/general-setup';

export default function Header({ isWebViewMode, pageTitle, isPublicPage }: INavbar) {
   const [menusState, setMenusState] = useState<IMenusState>({
      type: MENU_TYPE.USER,
      anchorEl: null,
   });
   const [sideSheetState, setSideSheetState] = useState<ISideSheetState>({
      type: SIDE_SHEET_TYPE.INVITE_USER,
      isVisible: false,
   });

   const { info: USER, defaultPage } = useAppSelector((state) => state?.user);
   const { pathname } = useLocation();

   const { companyLogoStyle, cursorPointer } = navbarStyles();
   const spacing = generalSpacing();
   const { t } = useTranslation();
   const { navigateTo } = useIitRouting();
   const theme = setupTheme();

   const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

   const handleOpenMenu = useCallback(
      (menuType: MENU_TYPE, event?: MouseEvent<HTMLElement>) => {
         setMenusState({
            type: menuType,
            anchorEl: event ? event.currentTarget : null,
         });
      },
      [],
   );

   const showHideSidesheet = useCallback(
      (show = false, type?: SIDE_SHEET_TYPE) => {
         setSideSheetState((prev) => {
            const isVisible = !sideSheetState.isVisible;
            if (show && type) return { type, isVisible };

            return {
               ...prev,
               isVisible,
            };
         });
         handleOpenMenu(MENU_TYPE.USER);
      },
      [handleOpenMenu, sideSheetState.isVisible],
   );

   const ANCHOR_EL = useCallback(
      (type: MENU_TYPE) => (menusState.type === type ? menusState.anchorEl : null),
      [menusState],
   );

   const SHOW_ICON_MOBILE: boolean = useMemo(
      () => showComponentMobile(pathname, isWebViewMode, isMobile),
      [isMobile, isWebViewMode, pathname],
   );

   return (
      <Box flexGrow={1} sx={{ maxHeight: theme.spacing(8) }}>
         <AppBar
            position="fixed"
            color="inherit"
            sx={{
               borderBottom: `1px solid ${themeColors.divider}`,
               boxShadow: 'none',
            }}
         >
            <Toolbar className={spacing.root}>
               {(!isWebViewMode && !isMobile) || isPublicPage ? (
                  <img
                     className={companyLogoStyle}
                     src={companyLogo}
                     alt="Logo"
                     onClick={() => navigateTo(defaultPage)}
                  />
               ) : (
                  <Box display="flex" alignItems="center">
                     {!SHOW_ICON_MOBILE && (
                        <IconButton
                           color="inherit"
                           sx={{ mr: 0.5, pl: 0.5 }}
                           onClick={() => navigateTo(-1)}
                        >
                           <ArrowBackIosNewIcon />
                        </IconButton>
                     )}
                     <Typography variant="h5">{t(pageTitle || '')}</Typography>
                  </Box>
               )}

               <Box flexGrow={1} />
               {!isPublicPage && (
                  <Box display="flex" alignItems="center">
                     {(isMobile || isWebViewMode) && (
                        <Box
                           className={cursorPointer}
                           onClick={(event) =>
                              handleOpenMenu(MENU_TYPE.MODULE_OPTIONS, event)
                           }
                           marginRight={3}
                        >
                           <MoreVertIcon />
                        </Box>
                     )}

                     {!isMobile && !isWebViewMode && (
                        <Box
                           onClick={(event) => handleOpenMenu(MENU_TYPE.USER, event)}
                           className={cursorPointer}
                        >
                           <IitProfileImage
                              image={USER?.image}
                              name={USER?.fullName || ''}
                              color={
                                 USER?.roles.includes(PORTAL_ROLES.ADMIN)
                                    ? 'primary'
                                    : 'secondary'
                              }
                           />
                        </Box>
                     )}

                     <HeaderUserMenu
                        handleCloseUserMenu={() => handleOpenMenu(MENU_TYPE.USER)}
                        anchorElUser={ANCHOR_EL(MENU_TYPE.USER)}
                        currentUser={USER}
                        showHideSidesheet={showHideSidesheet}
                     />
                  </Box>
               )}
            </Toolbar>
         </AppBar>
      </Box>
   );
}
